import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { CopyOutlined } from '@ant-design/icons';
import {
  Form, Input, Select, Button, message, Tabs, Alert,
} from 'antd';
import copy from 'copy-to-clipboard';
import { Helmet } from 'react-helmet';
import ky from 'ky';

import { tabOptions as tabs } from '../../config';
import AuthedPage from '../AuthedPage';
import './LinkGenerator.scss';
import { FolderObj } from '../../types';
import { collection, getDocs, getFirestore, query, where } from 'firebase/firestore';

const { Item: FormItem } = Form;

const screenTypes = {
  tab: 'Tab',
  folder: 'Folder',
  doc: 'Doc',
  settings: 'Settings',
  other: 'Other',
};

const tabOptions = Object.keys(tabs).map(
  (key) => ({ value: key, label: tabs[key as keyof typeof tabs] }),
);

const otherOptions = [
  { value: 'bell', label: 'Bell of Mindfulness', tab: 'extras' },
  { value: 'meditation-timer', label: 'Meditation Timer', tab: 'extras' },
  { value: 'new-docs', label: 'Latest Updates', tab: 'extras' },
  { value: 'favorites', label: 'Favorites', tab: 'extras' },
  { value: 'downloads', label: 'Downloads', tab: 'extras' },
  { value: 'retreats', label: 'Retreats', tab: 'extras' },
  { value: 'playlists', label: 'Playlists', tab: 'extras' },
  { value: 'user-account', label: 'My Account', tab: 'extras' },
  { value: 'user-activity', label: 'My Activity', tab: 'extras' },
  { value: 'about-plum-village', label: 'About Plum Village', tab: 'extras' },
  { value: 'contact', label: 'Contact', tab: 'extras' },
];

const settingOptions = [
  { value: 'settings', label: 'Settings (menu)', tab: 'extras' },
  { value: 'language-setting', label: 'Languages', tab: 'extras' },
  { value: 'interface-language-setting', label: 'Interface Language', tab: 'extras' },
  { value: 'appearance-setting', label: 'Appearance', tab: 'extras' },
  { value: 'subtitle-setting', label: 'Subtitles', tab: 'extras' },
  { value: 'playback-speed-setting', label: 'Playback', tab: 'extras' },
  { value: 'activity-setting', label: 'Activity', tab: 'extras' },
  { value: 'apple-health-setting', label: 'Apple Health', tab: 'extras' },
  { value: 'expensive-network-warning-setting', label: 'Mobile Data', tab: 'extras' },
  { value: 'sd-card-setting', label: 'SD Card', tab: 'extras' },
];

const getLinkPath = ({ value, tab }: { value: string, tab?: string }) => (tab ? `${tab}/${value}` : `${value}`);

const getDeepLink = ({ value, tab }: { value: string, tab?: string }) => {
  const prefix = 'plumvillage://';
  return `${prefix}${getLinkPath({ value, tab })}`;
};

type LinkItem = {
  value: string,
  label: string,
  tab?: string,
};

const API_KEY = 'AIzaSyChoJPE26zIMOYk8Iu2-n5LgZafZts20-M';

const LinkGenerator = () => {
  const [screenType, setScreenType] = useState<string>('tab');
  const [selectedTab, setSelectedTab] = useState<string>('dynamic');
  const [folders, setFolders] = useState<Array<LinkItem>>([]);
  const [docs, setDocs] = useState<Array<LinkItem>>([]);
  const [selectedScreen, setSelectedScreen] = useState<string | null>();
  const [dynamicLink, setDynamicLink] = useState<string | null>(null);

  useEffect(() => {
    // load folders
    getDocs(query(collection(getFirestore(), 'folders'), where('visibility', '==', 'public')))
      .then((querySnapshot) => {
        if (querySnapshot.metadata.fromCache) {
          throw new Error('No Internet!');
        }

        const folderItems: Array<LinkItem> = [];
        querySnapshot.forEach((item) => {
          const { title: label, tab } = item.data() as FolderObj;
          folderItems.push({ value: `folders/${item.id}`, label: `${tab} / ${label}`, tab });
        });
        setFolders(folderItems);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
        message.error('Oops! something went wrong while loading folder options.');
      });

    // load docs
    getDocs(query(collection(getFirestore(), 'docs'), where('visibility', '==', 'public')))
      .then((querySnapshot) => {
        if (querySnapshot.metadata.fromCache) {
          throw new Error('No Internet!');
        }

        const docItems: Array<LinkItem> = [];
        querySnapshot.forEach((doc) => {
          const { title: label, tab } = doc.data();
          docItems.push({ value: `docs/${doc.id}`, label: `${tab} / ${label}`, tab });
        });
        setDocs(docItems);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
        message.error('Oops! something went wrong while loading doc options.');
      });
  }, []);

  const screenOptions = useMemo(() => ({
    tab: tabOptions,
    folder: folders,
    doc: docs,
    settings: settingOptions,
    other: otherOptions,
  }[screenType]), [docs, folders, screenType]);

  const selectedScreenObj = useMemo(() => (selectedScreen && screenOptions
    ? screenOptions.find(({ value }) => value === selectedScreen)
    : null), [screenOptions, selectedScreen]);

  useEffect(() => {
    if (selectedTab === 'dynamic' && screenType && selectedScreen && selectedScreenObj) {
      let link = 'https://web.plumvillage.app/'; // fallback
      if (screenType === 'docs') {
        // New format for docs without tab name
        link = `https://web.plumvillage.app/item/${selectedScreen}`;
      } else {
        // May not yet be supported by web.plumvillage.app but will work in the mobile app
        link = `https://web.plumvillage.app/${getLinkPath(selectedScreenObj)}`;
      }

      ky.post(`https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${API_KEY}`, {
        json: {
          dynamicLinkInfo: {
            link,
            domainUriPrefix: 'https://link.plumvillage.app',
            socialMetaTagInfo: {
              socialImageLink: 'https://firebasestorage.googleapis.com/v0/b/plum-village.appspot.com/o/share-preview-audio.jpg?alt=media',
            },
            iosInfo: {
              iosBundleId: 'org.plumvillageapp',
              iosAppStoreId: '1273719339',
            },
            androidInfo: {
              androidPackageName: 'org.plumvillageapp',
              androidMinPackageVersionCode: '233',
            },
          },
          suffix: {
            option: 'SHORT',
          },
        },
      }).then((result) => {
        if (result.ok) {
          result.json<{ shortLink: string }>().then((body) => {
            setDynamicLink(body.shortLink);
          });
        } else {
          message.error('Failed to generate dynamic link');
        }
      });
    } else {
      setDynamicLink(null);
    }
  }, [selectedTab, screenType, selectedScreen, selectedScreenObj]);

  const handleScreenTypeChange = useCallback((value: string) => {
    setScreenType(value);
    setSelectedScreen(null);
  }, []);

  const handleScreenSelect = useCallback((value: string) => {
    setSelectedScreen(value);
  }, []);

  const copyLink = useCallback((text: string) => {
    copy(text);
    message.success('Copied!');
  }, []);

  const screenTypeSelector = useMemo(() => (
    <Select value={screenType} style={{ width: 120 }} onChange={handleScreenTypeChange}>
      {Object.keys(screenTypes).map((key) => (
        <Select.Option key={key} value={key}>{screenTypes[key as keyof typeof screenTypes]}</Select.Option>
      ))}
    </Select>
  ), [screenType, handleScreenTypeChange]);

  const screenSelector = useMemo(() => (
    <Select
      loading={screenType === 'folder' ? folders.length === 0 : (screenType === 'doc' ? docs.length === 0 : false)}
      showSearch
      value={selectedScreen || undefined}
      style={{ width: 380 }}
      onChange={handleScreenSelect}
      optionFilterProp="children"
      placeholder={screenType !== 'other'
        ? (screenType !== 'settings' ? `Select a ${screenType}` : 'Select a setting screen')
        : 'Select an item'}
    >
      {screenOptions && screenOptions.map(({ value, label }) => (
        <Select.Option key={value} value={value}>{label}</Select.Option>
      ))}
    </Select>
  ), [selectedScreen, handleScreenSelect, screenType, screenOptions, folders, docs]);

  let linkBox = null;

  let link: string | null = null;
  if (selectedScreenObj && selectedTab === 'deep') {
    link = getDeepLink(selectedScreenObj);
  } else if (selectedTab === 'dynamic') {
    link = dynamicLink;
  }

  if (link) {
    const copyToClipboardBtn = (
      <Button
        icon={<CopyOutlined />}
        type="primary"
        onClick={() => link && copyLink(link)}
      />
    );

    linkBox = (
      <Input
        className="ant-input-copy-to-clipboard"
        value={link}
        suffix={copyToClipboardBtn}
        readOnly
      />
    );
  }

  const tabItems = [
    {
      label: 'Dynamic Links',
      key: 'dynamic',
      children: (
        <>
          <Form layout="inline">
            <FormItem>
              {screenTypeSelector}
            </FormItem>
            <FormItem>
              {screenSelector}
            </FormItem>
          </Form>
          <br />
          {linkBox}
          {link && screenType !== 'docs' && (
            <Alert
              message="This link is not yet supported on desktop (web.plumvillage.app) but will work if opened on mobile."
              type="info"
              style={{ margin: '1em' }}
              showIcon
            />
          )}
        </>
      ),
    },
    {
      label: 'Deep Links (legacy)',
      key: 'deep',
      children: (
        <>
          <Alert
            message="Deep link support pre-dates dynamic link support in the app and generally should not be used except for testing. Deep links are less reliable as they require the app to be installed for the link to work"
            type="info"
            style={{ margin: '1em' }}
            showIcon
          />
          <Form layout="inline">
            <FormItem>
              {screenTypeSelector}
            </FormItem>
            <FormItem>
              {screenSelector}
            </FormItem>
          </Form>
          <br />
          {linkBox}
        </>
      ),
    },
  ];

  return (
    <AuthedPage requiredPermission={null}>
      <Helmet>
        <title>Link Generator</title>
      </Helmet>

      <Tabs defaultActiveKey="dynamic" onChange={setSelectedTab} items={tabItems} />
    </AuthedPage>
  );
};

export default LinkGenerator;
