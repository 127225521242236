import React, {
  useState,
  useCallback, useEffect,
} from 'react';
import { SwapOutlined } from '@ant-design/icons';
import {
  Button,
  Modal,
  Spin,
  message,
} from 'antd';
import { collection, doc, getFirestore, serverTimestamp, Timestamp, writeBatch } from 'firebase/firestore';

import type { FolderObj, TabValue } from '../../../types';
import './Reorder.scss';
import SortableList from './SortableList';
import { arrayMove } from '@dnd-kit/sortable';
import { DragEndEvent } from '@dnd-kit/core/dist/types';
import useFolders from '../../../hooks/useFolders';

type Props = {
  disabled: boolean;
  tab: TabValue;
  reloadMainTable: () => void;
};

export default function ReorderFolders({ disabled, tab, reloadMainTable }: Props) {
  const { folders, isLoadingFolders, reloadFolders } = useFolders(tab);
  const [pendingFolders, setPendingFolders] = useState<FolderObj[]>([]);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  useEffect(() => {
    setPendingFolders(folders);
  }, [folders]);

  const showModal = useCallback(() => {
    setModalVisible(true);
    setPendingFolders([]);

    // Reload folders every time the modal is opened
    reloadFolders();
  }, [reloadFolders]);

  const handleSave = useCallback(async () => {
    setIsSaving(true);

    const batch = writeBatch(getFirestore());

    pendingFolders.forEach(({ id, order }) => {
      const itemRef = doc(collection(getFirestore(), 'folders'), id);

      const oldFolder = folders.find(f => f.id === id);

      if (oldFolder) {
        const dataToUpdate: {
          order?: number,
          updatedAt?: Timestamp,
        } = {};

        if (order !== oldFolder.order) {
          // Order within primary folder was changed
          dataToUpdate.order = order;
        }

        if (Object.keys(dataToUpdate).length > 0) {
          dataToUpdate.updatedAt = serverTimestamp() as Timestamp;
          batch.update(itemRef, dataToUpdate);
        }
      }
    });

    try {
      await batch.commit();
      setModalVisible(false);
      // Reload main table/list
      reloadMainTable();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      message.error('Oops! something went wrong saving new order to database.');
    }

    setIsSaving(false);
  }, [folders, pendingFolders, reloadMainTable]);

  const handleCancel = useCallback(() => {
    setModalVisible(false);
  }, []);

  const onDragEnd: (event: DragEndEvent) => void = useCallback((event) => {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      setPendingFolders((prevFolders) => {
        const oldIndex = prevFolders.findIndex((f) => f.id === active.id);
        const newIndex = prevFolders.findIndex((f) => f.id === over.id);

        const sortedFolders = arrayMove(prevFolders, oldIndex, newIndex);
        return sortedFolders.map((item, index) => ({ ...item, order: index }));
      });
    }
  }, []);

  return (
    <>
      <Button
        type="primary"
        ghost
        onClick={showModal}
        disabled={disabled}
        icon={<SwapOutlined />}
      >
        Reorder
      </Button>
      <Modal
        title="Reorder folders"
        open={modalVisible}
        okText="Save"
        onOk={handleSave}
        confirmLoading={isSaving}
        onCancel={handleCancel}
      >
        <Spin spinning={isLoadingFolders}>
          <SortableList
            items={pendingFolders}
            onDragEnd={onDragEnd}
          />
        </Spin>
      </Modal>
    </>
  );
}
