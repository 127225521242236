import React, {
  useState, useCallback, RefObject, ReactNode,
} from 'react';
import type { Ref } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Alert, Button, Input, InputRef } from 'antd';
import SearchDropdownWrap from '../components/SearchDropdownWrap';
import { FilterDropdownProps } from 'antd/lib/table/interface';

function useSearchFilter() {
  const [searchText, setSearchText] = useState<string>('');
  const [searchColumn, setSearchColumn] = useState<string>('');

  const handleSearch = useCallback((selectedKeys: Array<React.Key>, confirm: () => void, field: string) => {
    confirm();
    setSearchText(selectedKeys[0] as string);
    setSearchColumn(field);
  }, []);

  const handleReset = useCallback((clearFilters: (() => void) | undefined, confirm: () => void) => {
    if (clearFilters) {
      clearFilters();
    }
    confirm();
    setSearchText('');
  }, []);

  const onFilterDropdownOpenChange = (
    searchInputRef: RefObject<InputRef>,
  ) => (visible: boolean) => {
    if (visible) {
      setTimeout(() => {
        if (searchInputRef && searchInputRef.current) {
          searchInputRef.current.focus();
        }
      });
    }
  };
  const searchFilterIcon = useCallback((filtered: boolean) => (
    <SearchOutlined style={{ color: filtered ? '#108ee9' : '#aaa' }} />
  ), []);

  const filterDropdown = (field: string, searchInputRef: Ref<InputRef>) => ({
    setSelectedKeys, selectedKeys, confirm, clearFilters,
  }: FilterDropdownProps): ReactNode => (
    <SearchDropdownWrap>
      {field === 'sourceURL' && (
        <Alert
          message="Recommend to use a YouTube ID instead of the full URL."
          description={
            <>
              For example if the URL is <code>https://www.youtube.com/watch?v=9DwYV_Ipiuk</code>,
              then the ID is <code>9DwYV_Ipiuk</code>.
              This will improve the chance of a match.
            </>
          }
          type="info"
          closable
        />
      )}

      <Input
        ref={searchInputRef}
        placeholder={`Search ${field}`}
        value={selectedKeys[0]}
        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => handleSearch(selectedKeys, confirm, field)}
      />
      <Button type="primary" onClick={() => handleSearch(selectedKeys, confirm, field)}>
        Search
      </Button>
      <Button onClick={() => handleReset(clearFilters, confirm)}>Reset</Button>
    </SearchDropdownWrap>
  );

  const renderHighlight = (text: string) => (searchText ? (
    <span>
      {text.split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))
        .map((fragment, i) => (
          fragment.toLowerCase() === searchText.toLowerCase()
            // eslint-disable-next-line react/no-array-index-key
            ? <span key={i} className="highlight">{fragment}</span> : fragment
        ))}
    </span>
  ) : (
    <span>{text}</span>
  ));

  return {
    searchText, searchColumn, onFilterDropdownOpenChange, filterDropdown, searchFilterIcon, renderHighlight,
  };
}

export default useSearchFilter;
