import * as React from 'react';

import './SearchDropdownWrap.scss';

type Props = {
  children: React.ReactNode,
};

const SearchDropdownWrap = ({ children }: Props) => (
  <div className="custom-search-dropdown">
    {children}
  </div>
);

export default SearchDropdownWrap;
