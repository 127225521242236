import React from 'react';
import { DownloadOutlined, PlusOutlined, ReloadOutlined } from '@ant-design/icons';
import { Button } from 'antd';

import ReorderFolders from './ReorderFolders';
import ReorderDocs from './ReorderDocs';
import './Header.scss';
import { TabValue } from '../../../types';

type Props = {
  loading: boolean,
  tab: TabValue,
  collection: string,
  onReload: () => void,
  onAddNew: () => void,
  onExportCsv: () => void,
  isGeneratingCsv: boolean,
};

const Header = ({
  loading, tab, collection, onReload, onAddNew, onExportCsv, isGeneratingCsv,
}: Props) => (
  <div className="DocsAndFoldersHeader__wrapper">
    <Button
      type="primary"
      ghost
      onClick={onReload}
      loading={loading}
      icon={<ReloadOutlined />}
    >
      Reload
    </Button>
    {collection === 'docs' && (
      <ReorderDocs
        disabled={loading}
        tab={tab}
        reloadMainTable={onReload}
      />
    )}
    {collection === 'folders' && (
      <ReorderFolders
        disabled={loading}
        tab={tab}
        reloadMainTable={onReload}
      />
    )}
    <Button
      type="primary"
      onClick={onAddNew}
      disabled={loading}
      icon={<PlusOutlined />}
    >
      Add new
    </Button>
    <div className="DocsAndFoldersHeader__spacer" />
    <Button
      disabled={loading}
      loading={isGeneratingCsv}
      onClick={onExportCsv}
      icon={<DownloadOutlined />}
    >
      Export CSV
    </Button>
  </div>
);

export default Header;
