import React from 'react';
import { Tabs } from 'antd';
import { Helmet } from 'react-helmet';

import AuthedPage from '../AuthedPage';
import ErrorBoundary from '../ErrorBoundary';
import Files from './Files';
import MediaUpload from './MediaUpload';
import './Audio.scss';

const Audio = () => {
  const tabItems = [
    {
      label: 'Files',
      key: 'files',
      children: (
        <ErrorBoundary>
          <Files />
        </ErrorBoundary>
      ),
    },
    {
      label: 'Upload',
      key: 'upload',
      children: (
        <ErrorBoundary>
          <MediaUpload />
        </ErrorBoundary>
      ),
    },
  ];

  return (
    <AuthedPage requiredPermission="edit_content">
      <Helmet>
        <title>Audio files</title>
      </Helmet>

      <br />

      <Tabs defaultActiveKey="files" items={tabItems} />
    </AuthedPage>
  );
};

export default Audio;
