import { initializeApp } from 'firebase/app';
import { connectFirestoreEmulator, initializeFirestore } from 'firebase/firestore';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';

// Firebase config
const firebaseConfig = {
  apiKey: 'AIzaSyChoJPE26zIMOYk8Iu2-n5LgZafZts20-M',
  authDomain: 'plum-village.firebaseapp.com',
  databaseURL: 'https://plum-village.firebaseio.com',
  projectId: 'plum-village',
  storageBucket: 'plum-village.appspot.com',
  messagingSenderId: '552620441503',
  appId: '1:552620441503:web:d9e002704cc2ee0e6647aa',
};
const firebaseApp = initializeApp(firebaseConfig);
const firestore = initializeFirestore(firebaseApp, { experimentalForceLongPolling: true });
if (process.env.NODE_ENV !== 'production') {
  connectFunctionsEmulator(getFunctions(), '127.0.0.1', 5001);
  connectFirestoreEmulator(firestore, '127.0.0.1', 8080);
}
