import React, {
  useRef,
} from 'react';
import {
  Table, Tag,
} from 'antd';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';

import type { PaymentObj } from '../../../types';
import {
  dateFilterOptions, getPaymentStatusColour, getPaymentStatusDescription,
} from '../paymentHelpers';
import useSearchFilter from '../../../hooks/useSearchFilter';

const { Column } = Table;

/**
 * Internally we use the generic term "Payment" but in the UI we use "Donation"
 */
const Payments = ({ payments }: { payments: Array<PaymentObj> }) => {
  const emailFilterRef = useRef(null);
  const nameFilterRef = useRef(null);

  const { filterDropdown, searchFilterIcon, onFilterDropdownOpenChange } = useSearchFilter();

  return (
    <>
      <Table
        dataSource={payments}
        rowKey={(payment: PaymentObj) => payment.id}
        locale={{ emptyText: 'Sorry, you do not have any donations (yet).' }}
        scroll={{ x: 1000 }}
        summary={(pageData) => {
          let totalPayments = 0;
          let totalValue = 0;

          pageData.forEach(({ amount }) => {
            totalValue += amount;
            totalPayments += 1;
          });

          return (
            <Table.Summary.Row>
              <Table.Summary.Cell index={1} colSpan={3}>{`Total for the ${totalPayments} donations on this page`}</Table.Summary.Cell>
              <Table.Summary.Cell index={2}>{`$${totalValue.toFixed(2)}`}</Table.Summary.Cell>
              <Table.Summary.Cell index={3} colSpan={3}> </Table.Summary.Cell>
            </Table.Summary.Row>
          );
        }}
      >
        <Column
          title="Date"
          dataIndex="paymentDate"
          render={(paymentDate, payment) => (
            <Link to={`/crm/payments/${payment.id}`} title="View donation">
              {dayjs(paymentDate).format('YYYY-MM-DD HH:mm')}
            </Link>
          )}
          sorter={(a: PaymentObj, b: PaymentObj) => a.paymentDate.localeCompare(b.paymentDate)}
          defaultSortOrder="descend"
          filters={dateFilterOptions}
          filterMultiple={false}
          onFilter={(value, payment: PaymentObj) => {
            const yesterday = dayjs().startOf('day').subtract(1, value as dayjs.ManipulateType);
            return yesterday.isBefore(dayjs(payment.paymentDate));
          }}
        />
        <Column
          title="Email"
          dataIndex="email"
          sorter={(a: PaymentObj, b: PaymentObj) => a.email.localeCompare(b.email)}
          render={(email) => (
            <Link to={`/crm/customers/${email}`} title="View donor">
              {email}
            </Link>
          )}
          filterDropdown={filterDropdown('email', emailFilterRef)}
          filterIcon={searchFilterIcon}
          onFilter={(value, payment: PaymentObj) => payment
            .email.toLowerCase().includes((value as string).toLowerCase())}
          onFilterDropdownOpenChange={onFilterDropdownOpenChange(emailFilterRef)}
        />
        <Column
          title="Name"
          dataIndex="customerName"
          sorter={(a: PaymentObj, b: PaymentObj) => a.customerName.localeCompare(b.customerName)}
          filterDropdown={filterDropdown('name', nameFilterRef)}
          filterIcon={searchFilterIcon}
          onFilter={(value, payment: PaymentObj) => payment
            .customerName.toLowerCase().includes((value as string).toLowerCase())}
          onFilterDropdownOpenChange={onFilterDropdownOpenChange(nameFilterRef)}
        />
        <Column
          title="Amount"
          dataIndex="amount"
          sorter={(a: PaymentObj, b: PaymentObj) => a.amount - b.amount}
          render={(amount, payment) => `${amount} ${payment.currency}`}
        />
        <Column
          title="Status"
          dataIndex="status"
          render={(status, payment: PaymentObj) => (
            <Tag
              color={getPaymentStatusColour(payment.status)}
              title={getPaymentStatusDescription(payment.status)}
            >
              {payment.status}
            </Tag>
          )}
          filters={[{
            text: 'Success',
            value: 'green',
          }, {
            text: 'Failure',
            value: 'red',
          }, {
            text: 'Other',
            value: 'grey',
          }]}
          onFilter={(
            value, payment: PaymentObj,
          ) => getPaymentStatusColour(payment.status) === value}
        />
        <Column
          title="Subscription?"
          dataIndex="isSubscription"
          render={(isSubscription) => (isSubscription ? 'Yes' : 'No')}
          filters={[{
            text: 'Yes',
            value: true,
          }, {
            text: 'No',
            value: false,
          }]}
          onFilter={(value, payment: PaymentObj) => payment.isSubscription === value}
        />
        <Column
          title="Platform"
          dataIndex="platform"
          render={(platform) => (platform === 'paypal' ? 'PayPal' : (platform.charAt(0).toUpperCase() + platform.slice(1)))}
          filters={[{
            text: 'Stripe',
            value: 'stripe',
          }, {
            text: 'PayPal',
            value: 'paypal',
          }]}
          onFilter={(value, payment: PaymentObj) => payment.platform === value}
        />
      </Table>
    </>
  );
};

export default Payments;
