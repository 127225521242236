import React, { useEffect, useState } from 'react';
import { Layout } from 'antd';
import classNames from 'classnames';
import { getAuth } from 'firebase/auth';

import ErrorBoundary from '../ErrorBoundary';
import PageHeader from './PageHeader';
import MainMenu from './MainMenu';
import logo from '../../images/logo-dark.png';
import './AuthedPage.scss';
import type { Permission } from '../../types';

const { Content, Footer, Sider } = Layout;

type Props = {
  requiredPermission: Permission | void | null,
  children: React.ReactNode,
  fullwidth?: boolean,
  noBackground?: boolean,
};

const currentYear = (new Date()).getFullYear();
const copyrightYear = currentYear === 2018 ? '2018' : `2018 - ${currentYear}`;

const AuthedPage = ({
  requiredPermission, children, fullwidth = false, noBackground = false,
}: Props) => {
  const [loadingClaims, setLoadingClaims] = useState<boolean>(true);
  const [hasAccess, setHasAccess] = useState<boolean>(false);

  useEffect(() => {
    const { currentUser } = getAuth();
    if (requiredPermission && currentUser) {
      currentUser.getIdTokenResult(true)
        .then((idTokenResult) => {
          setHasAccess(idTokenResult.claims[requiredPermission] as boolean);
          setLoadingClaims(false);
        })
        .catch(() => {
          setLoadingClaims(false);
        });
    } else {
      setHasAccess(true);
      setLoadingClaims(false);
    }
  }, [requiredPermission]);

  return (
    <Layout>
      <Sider className="app-sidebar">
        <div className="logo">
          <img src={logo} alt="Plum Village logo" />
        </div>
        <ErrorBoundary>
          <MainMenu />
        </ErrorBoundary>
      </Sider>
      <Layout className="layout-right">
        <ErrorBoundary>
          <PageHeader />
        </ErrorBoundary>
        <Content className="app-content">
          <div
            className={classNames(
              'app-content__inner',
              fullwidth && 'app-content__inner--fullwidth',
              noBackground && 'app-content__inner--no-bg',
            )}
          >
            {loadingClaims ? (<p>Loading...</p>) : (
              <>
                {hasAccess ? (
                  <ErrorBoundary>
                    {children}
                  </ErrorBoundary>
                ) : (
                  <p>You do not have access to this page.</p>
                )}
              </>
            )}
          </div>
        </Content>
        <Footer className="app-footer">
          {`© ${copyrightYear} Plum Village.`}
        </Footer>
      </Layout>
    </Layout>
  );
};

export default AuthedPage;
