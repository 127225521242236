import React from 'react';
import { Layout, Avatar, Button } from 'antd';
import { useParams, useLocation } from 'react-router-dom';
import { getAuth } from 'firebase/auth';

import { menuItems } from '../../config';
import { metadataNameSingular, metadataTabkeys } from '../Metadata/MetadataWrapper';
import { capitalize } from '../../utils';

const { Header } = Layout;

type PageParams = {
  docId?: string,
  paymentId?: string,
  customerId?: string,
  metadataType?: string,
  metadataId: string,
};

const PageHeader = () => {
  const { currentUser, signOut } = getAuth();

  const { docId, paymentId, customerId, metadataType, metadataId } = useParams<PageParams>();
  const location = useLocation();
  const path = location.pathname;
  const pathSegment = path.substring(1).split('/')[0];
  const activeMenuItem = menuItems.find((menuItem) => menuItem.slug === pathSegment);

  let title = activeMenuItem
    ? activeMenuItem.pageTitle
    : '';

  if (docId) {
    title = docId === 'new' ? 'Add New Doc' : 'Edit Doc';
  }
  if (paymentId) {
    title = `Donation: ${paymentId}`;
  }
  if (customerId) {
    title = `Donor: ${customerId}`;
  }
  if (metadataType && metadataId) {
    const typeName = metadataNameSingular[metadataType as keyof typeof metadataTabkeys];
    const typeNamePascalCase = capitalize(typeName);
    let prepend = metadataId === 'new' ? 'Add New' : 'Edit';
    prepend = path.includes('/potential/') ? `${prepend} Potential` : prepend;
    title = `${prepend} ${typeNamePascalCase}`;
  }

  return (
    <Header className="app-header">
      <h1 className="app-header__title">{title}</h1>
      <div className="app-header__right">
        {currentUser && (
          <Avatar className="app-header__avatar" src={currentUser.photoURL} />
        )}
        <Button danger ghost onClick={signOut}>Sign out</Button>
      </div>
    </Header>
  );
};

export default PageHeader;
