import React, {
  useState, useCallback, useEffect, useRef, SyntheticEvent,
} from 'react';
import {
  Form, Modal, message, Input,
} from 'antd';
import Dropzone from 'react-dropzone';
import {
  LoadingOutlined, PictureOutlined,
} from '@ant-design/icons';
import { getFunctions, httpsCallable } from 'firebase/functions';

import './AddThumb.scss';

function getBase64(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
}

function sanitizeName(name: string) {
  return name.replace(/(@\dx)?.png/, '').replace(/[^a-zA-Z0-9_-]/g, '');
}

export type UploadFile = {
  uid: string,
  name: string,
  status: string,
  response: string,
  linkProps: string,
  xhr: string,
};

type ImgDimensions = { width: number, height: number };

type Props = {
  thumbs: string[],
  onDismiss: () => void,
};

function AddThumb({ thumbs, onDismiss }: Props) {
  const [file, setFile] = useState<File | null>(null);
  const [name, setName] = useState<string>('');
  const [formErrors, setFormErrors] = useState<{ [key: string]: string }>({});
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [imageData, setImageData] = useState<null | string>(null);
  const [imgDimensions, setImgDimensions] = useState<ImgDimensions | null>(null);

  const validateForm = useCallback(() => {
    // reset form errors
    const errors: { [key: string]: string } = {};

    if (!name) {
      errors.name = 'Name is required.';
    }

    if (thumbs.includes(name)) {
      errors.name = 'A thumbnail with this name already exists.';
    }

    if (!file) {
      errors.file = 'Please upload a thumbnail image.';
    }

    if (file && imgDimensions && Math.max(imgDimensions.width, imgDimensions.height) < 432) {
      errors.file = 'Image must be at least 432px in width or height.';
    }

    setFormErrors(errors);

    return Object.keys(errors).length === 0;
  }, [file, imgDimensions, name, thumbs]);

  const firstUpdate = useRef<boolean>(true);
  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    validateForm();
  }, [name, validateForm]);

  const handleFileSelect = useCallback(async (files: Array<File>) => {
    if (files.length < 1) {
      return;
    }

    const [newFile] = files;

    if (newFile.type !== 'image/png' || !newFile.name.endsWith('.png')) {
      return;
    }

    setFile(newFile);
    setImgDimensions(null);

    if (!name) {
      setName(sanitizeName(newFile.name));
    }

    const url = await getBase64(newFile);
    setImageData(url);
  }, [name]);

  const handleImageLoad = useCallback((event: SyntheticEvent<HTMLImageElement>) => {
    const img = event.currentTarget;
    setImgDimensions({
      width: img.naturalWidth,
      height: img.naturalHeight,
    });
  }, []);

  const handleNameChange = useCallback((event: SyntheticEvent<HTMLInputElement>) => {
    setName(sanitizeName(event.currentTarget.value));
  }, []);

  const handleSave = useCallback(async () => {
    if (!validateForm() || !imageData) {
      return;
    }

    setIsSaving(true);

    try {
      const newFolderThumbnail = httpsCallable<{ imageData: string, name: string }>(getFunctions(), 'newFolderThumbnail');

      await newFolderThumbnail({
        imageData,
        name,
      });
      onDismiss();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      message.error('Oops! something went wrong while uploading folder thumb.');
      setIsSaving(false);
    }
  }, [imageData, name, onDismiss, validateForm]);

  const savingDisabled = Object.keys(formErrors).length > 0;

  return (
    <Modal
      title="Add folder thumb"
      open
      confirmLoading={isSaving}
      okButtonProps={{ disabled: savingDisabled }}
      cancelButtonProps={{ disabled: isSaving }}
      okText="Save"
      onOk={handleSave}
      onCancel={onDismiss}
    >
      <Form.Item
        label="Thumbnail"
        validateStatus={formErrors.file ? 'error' : undefined}
        help={formErrors.file || (!file ? 'Please select a PNG image file' : '')}
      >
        <Dropzone onDrop={handleFileSelect} accept={{ 'image/png': ['.png'] }} multiple={false}>
          {({ getRootProps, getInputProps }) => (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <div {...getRootProps({ className: 'thumb-dropzone' })}>
              {/* eslint-disable-next-line react/jsx-props-no-spreading */}
              <input {...getInputProps()} />
              {file && imageData ? (
                <img alt="" style={{ maxWidth: '100%' }} src={imageData} onLoad={handleImageLoad} />
              ) : (
                <div className="thumb-dropzone-button">
                  {isSaving ? (
                    <LoadingOutlined style={{ fontSize: '32px', color: '#1890ff' }} />
                  ) : (
                    <PictureOutlined style={{}} />
                  )}
                  <div>Choose file</div>
                </div>
              )}
            </div>
          )}
        </Dropzone>
      </Form.Item>
      {file && (
        <Form.Item
          label="Unique name"
          hasFeedback={!!formErrors.name}
          validateStatus={formErrors.name ? 'error' : undefined}
          help={formErrors.name}
        >
          <Input
            value={name}
            onChange={handleNameChange}
          />
        </Form.Item>
      )}
    </Modal>
  );
}

export default AddThumb;
