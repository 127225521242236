import { useCallback, useEffect, useState } from 'react';
import { FolderObj, TabValue } from '../types';
import { collection, getDocs, getFirestore, query, where } from 'firebase/firestore';
import { sortCompare } from '../utils';
import { message } from 'antd';


const useFolders = (tab?: TabValue | undefined) => {
  const [folders, setFolders] = useState<Array<FolderObj>>([]);
  const [isLoadingFolders, setIsLoading] = useState<boolean>(true);

  const reloadFolders = useCallback(async () => {
    setIsLoading(true);
    try {
      const conditions: Array<ReturnType<typeof where>> = [];

      if (tab) {
        conditions.push(where('tab', '==', tab));
      }

      const querySnapshot = await getDocs(query(collection(getFirestore(), 'folders'), ...conditions));
      if (querySnapshot.metadata.fromCache) {
        throw new Error('No Internet!');
      }

      const filteredFolders: Array<FolderObj> = [];
      querySnapshot.forEach((folder) => {
        filteredFolders.push({ id: folder.id, ...folder.data() as Omit<FolderObj, 'id'> });
      });

      const sortedFolders = filteredFolders.sort(sortCompare);

      setFolders(sortedFolders);
      setIsLoading(false);
    } catch (err) {
      console.error(err);
      message.error('Oops! something went wrong while loading folders.');
    }
  }, [tab]);

  useEffect(() => {
    reloadFolders();
  }, [reloadFolders]);

  return { folders, isLoadingFolders, reloadFolders, setFolders };
};

export default useFolders;
