import { EditOutlined, ReloadOutlined } from '@ant-design/icons';
import { Button, InputRef, Table } from 'antd';
import { collection, getDocs, getFirestore, query } from 'firebase/firestore';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useSearchFilter from '../../hooks/useSearchFilter';
import { metadataTabkeys } from './MetadataWrapper';

export type PotentialMetadataType = {
  id: string,
  docs: string[],
  topic: string
};

const getEditUrl = (activeTab: keyof typeof metadataTabkeys, item: PotentialMetadataType) => {
  return `/metadata/${activeTab}/potential/${item.id}`;
};

const MetadataPotential = ({ activeTab } : { activeTab : keyof typeof metadataTabkeys }) => {
  
  const [loading, setLoading] = useState<boolean>(true);
  const [potentialMetadatas, setPotentialMetadatas] = useState<Array<PotentialMetadataType>>([]);
  const navigate = useNavigate();
 
  const searchInput = useRef<InputRef>(null);
  const { filterDropdown, renderHighlight, searchText, searchFilterIcon, onFilterDropdownOpenChange } = useSearchFilter();

  const columnRenderer = useCallback((value: string): JSX.Element => {
    const content = renderHighlight(value);
    return (
      <span style={{ whiteSpace: 'nowrap' }}>
      {content}
    </span>
    );
  }, [renderHighlight]);

  const reloadMetadatas = useCallback(async () => {

    setLoading(true);

    const potentialMetadataSnapshot = await getDocs(query(collection(getFirestore(), `${activeTab}-potential`)));

    if (potentialMetadataSnapshot.metadata.fromCache) {
      throw new Error('No Internet!');
    }
    const loadingPotentialMetadatas: Array<PotentialMetadataType> = [];

    potentialMetadataSnapshot.forEach((item) => {
      
      const data = item.data() as Omit<PotentialMetadataType, 'id'>;
      const metadataId = item.id;
      const metadata: PotentialMetadataType = {
        id: metadataId,
        ...data,
      };
      loadingPotentialMetadatas.push(metadata);
    });
    
    setPotentialMetadatas(loadingPotentialMetadatas);
    setLoading(false);
  }, [activeTab]);

  useEffect(() => {
    reloadMetadatas();
  }, [reloadMetadatas]);

  return (
  <>
  <div className="DocsAndFoldersHeader__wrapper">
    <Button
      type="primary"
      ghost
      loading={loading}
      icon={<ReloadOutlined />}
      onClick={reloadMetadatas}
    >
      Reload
    </Button>
  </div>
  <Table
    loading={loading}
    dataSource={potentialMetadatas}
    rowKey={(item: PotentialMetadataType) => item.id}
    locale={{ emptyText: searchText ? 'Nothing found matching your search' : `Sorry, you do not have any ${activeTab} (yet).` }}
    scroll={{ x: true }}
  >
    <Table.Column
      title="#"
      dataIndex=""
      width={'25%'}
      render={(item: PotentialMetadataType) => (
        <span style={{ whiteSpace: 'nowrap' }}>
          {potentialMetadatas.indexOf(item) + 1}
        </span>
      )}
      sorter={(a: PotentialMetadataType, b: PotentialMetadataType) => potentialMetadatas.indexOf(a) -  potentialMetadatas.indexOf(b)}
    />
    <Table.Column
      title="Name"
      dataIndex=""
      width={'25%'}
      filterDropdown={filterDropdown('title', searchInput)}
      filterIcon={searchFilterIcon}
      onFilter={(value, item: PotentialMetadataType) => item.id ? item
        .id.toLowerCase().includes((value as string).toLowerCase()) : false}
      onFilterDropdownOpenChange={onFilterDropdownOpenChange(searchInput)}
      render={(item: PotentialMetadataType) => 
        item.id ? columnRenderer(item.id) : <></>
      }
      sorter={(a: PotentialMetadataType, b: PotentialMetadataType) => a.topic.localeCompare(b.topic)}
    />
    <Table.Column
      title="Number of documents"
      dataIndex=""
      width={'25%'}
      render={(item: PotentialMetadataType) => 
        item.docs ? columnRenderer(item.docs.length.toString()) : <></>
      }
      sorter={(a: PotentialMetadataType, b: PotentialMetadataType) => a.docs.length - b.docs.length}
    />
    <Table.Column
        title="Actions"
        key="actions"
        width={'25%'}
        render={(text: string, item: PotentialMetadataType) => {
          return (
            <div style={{ whiteSpace: 'nowrap' }}>
                <Button
                    type="primary"
                    size="small"
                    icon={<EditOutlined />}
                    onClick={() => navigate(getEditUrl(activeTab, item))}
                />
            </div>
          );
        }}
    />
  </Table>
  </>
  );
};

export default MetadataPotential;