import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { metadataNameSingular, metadataTabkeys } from './MetadataWrapper';
import AuthedPage from '../AuthedPage';
import { Helmet } from 'react-helmet';
import { Divider, Spin, Table } from 'antd';
import { DocObj } from '../../types';
import { DocumentData, QueryDocumentSnapshot, collection, doc, documentId, getDoc, getDocs, getFirestore, query, where } from 'firebase/firestore';
import { FirestoreCollection } from '../../config';
import { PotentialMetadataType } from './MetadataPotential';
import sanitize from 'sanitize-html';

import './MetadataPotentialEdit.scss';
import chunk from 'lodash.chunk';

type PotentialMetadataWithDocs = {
  id: string,
  topic: string,
  docs: DocObj[]
};

const MetadataPotentialEdit = () => {

  const params = useParams();
  const { metadataType, metadataId } = params as { metadataType: keyof typeof metadataTabkeys, metadataId: string };

  const [potentialMetadataWithDocs, setPotentialMetadataWithDocs] = useState<PotentialMetadataWithDocs>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const initialLoad = useCallback(async () => {
    // In case we ever create a categories-potential collection, change this line to be dynamic instead of FirestoreCollection.TOPICS_POTENTIAL.
    const potentialMetadataSnapshot = await getDoc(doc(collection(getFirestore(), FirestoreCollection.TOPICS_POTENTIAL), metadataId));
    if (potentialMetadataSnapshot.metadata.fromCache) {
      throw new Error('No Internet!');
    }    
    if (!potentialMetadataSnapshot.exists()) {
      throw new Error(`${metadataId} does not exist in ${FirestoreCollection.TOPICS_POTENTIAL} collection`);
    }
    const potentialMetadata = potentialMetadataSnapshot.data() as PotentialMetadataType;
    const associatedDocs: DocObj[] = [];

    const chunksOfDocIds = chunk(potentialMetadata.docs, 30);
    const allDocs: QueryDocumentSnapshot<DocumentData, DocumentData>[] = [];
    for (const chunkOfDocIds of chunksOfDocIds) {
      const docsQuery = query(collection(getFirestore(), FirestoreCollection.DOCS), where(documentId(), 'in', chunkOfDocIds));
      const chunkOfDocs = (await getDocs(docsQuery)).docs;
      allDocs.push(...chunkOfDocs);
    }

    for (const document of allDocs) {
      
      const docObj = document.data() as DocObj;
      docObj.id = document.id;

      const sanitizedContent = sanitize(docObj.content ?? '');
      const regExp = new RegExp(metadataId, 'gi');

      const highlightedContent = sanitizedContent.replaceAll(regExp, (value) => `<mark>${value}</mark>`);
      docObj.content = highlightedContent;

      const highlightedTitle = docObj.title.replaceAll(regExp, (value) => `<mark>${value}</mark>`);
      docObj.title = `<html>${highlightedTitle}</html>`;

      associatedDocs.push(docObj);
    }
    setPotentialMetadataWithDocs({
      id: potentialMetadataSnapshot.id,
      topic: potentialMetadata.topic,
      docs: associatedDocs,
    });

    setIsLoading(false);
  }, [metadataId]);

  useEffect(() => {
    initialLoad();
  }, [initialLoad]);

  return (
    <AuthedPage noBackground fullwidth requiredPermission="edit_content">
      <Helmet>
        <title>Potential {metadataNameSingular[metadataType]} - {metadataId}</title>
      </Helmet>
    <Spin spinning={isLoading} delay={2000}>
      <div className="DocActionButtons DocActionButtons--top">
        <div className="DocActionButtons__container">
          { <h1>{metadataId}</h1> }
        </div>
      </div>
      <Divider />
      <Table
        loading={isLoading}
        dataSource={potentialMetadataWithDocs?.docs}
        rowKey={(item: DocObj) => item.id}
        locale={{ emptyText: `Sorry, there are no documents associated with "${metadataId}".` }}
        scroll={{ x: true }}
      >
        <Table.Column
          title="#"
          dataIndex=""
          render={(item: DocObj) => (
            <span style={{ whiteSpace: 'nowrap' }}>
              {(potentialMetadataWithDocs?.docs || []).indexOf(item) + 1}
            </span>
          )}
        />
        <Table.Column
          title="Document Title"
          dataIndex=""
          render={(item: DocObj) => (
            <div style={{ whiteSpace: 'nowrap' }} dangerouslySetInnerHTML={{ __html: sanitize(item.title) }} />
          )}
        />
        <Table.Column
          title="Document Content"
          dataIndex=""
          render={(item: DocObj) => (
            item.content ? <div dangerouslySetInnerHTML={{ __html: sanitize(item.content) }} /> : <span><i>No content was available for this document.</i></span>
          )}
        />
      </Table>
    </Spin>
    </AuthedPage>
  );
};

export default MetadataPotentialEdit;