import { useMemo } from 'react';
import { ColumnFilterItem } from 'antd/es/table/interface';

import { FolderObj } from '../types';

export const useFolderColumnFilterItems = (folders: Array<FolderObj>, includeTabName = false) => {
  const folderOptions = useMemo(() => {
    const getFolderOption = (folder: FolderObj): ColumnFilterItem => {
      const childFolders = folders.filter((f) => f.parent === folder.id);

      return {
        text: includeTabName && !folder.parent ? `${folder.title} (${folder.tab})` : folder.title,
        value: folder.id,
        children: childFolders.length === 0 ? undefined : childFolders.map(getFolderOption),
      };
    };

    return folders.filter((folder) => !folder.parent).map(getFolderOption);
  }, [folders, includeTabName]);

  return folderOptions;
};
