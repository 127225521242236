import React, { ForwardedRef, forwardRef, useState } from 'react';
import {
  closestCenter,
  DndContext,
  DragOverlay, DragStartEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { DragEndEvent } from '@dnd-kit/core/dist/types';
import { CSS } from '@dnd-kit/utilities';

import { DocObj, FolderObj } from '../../../types';

type Props = {
  items: Array<FolderObj | DocObj>,
  onDragEnd: (event: DragEndEvent) => void,
};

const DragHandle = () => <span className="sortable-handle">&#8942;&#8942;</span>;

const SortableItem = ({ id, title }: { id: string, title: string }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div className="Reorder__list__item" ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <DragHandle />
      {title}
    </div>
  );
};

export const Item = forwardRef(({ id, title, ...props }: { id: string, title: string }, ref: ForwardedRef<HTMLDivElement>) => {
  return (
    <div className="Reorder__list__item" {...props} ref={ref}>
      <DragHandle />
      {title}
    </div>
  );
});

const SortableList = ({ items, onDragEnd } : Props) => {
  const [activeItem, setActiveItem] = useState<DocObj | FolderObj | undefined>();
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  function handleDragStart(event: DragStartEvent) {
    const { active } = event;
    setActiveItem(items.find((item: FolderObj | DocObj) => item.id === active.id as string));
  }

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragStart={handleDragStart}
      onDragEnd={onDragEnd}
    >
      <SortableContext
        items={items}
        strategy={verticalListSortingStrategy}

      >
        {items.map(item => (
          <SortableItem key={item.id} id={item.id} title={item.title} />
        ))}
      </SortableContext>
      <DragOverlay>
        {activeItem ? <Item id={activeItem.id} title={activeItem.title} /> : null}
      </DragOverlay>
    </DndContext>
  );
};

export default SortableList;
