import React from 'react';
import { Tag } from 'antd';
import type { Visibility } from '../types';

type Props = {
  visibility: Visibility,
};

const VisibilityTag = ({ visibility }: Props) => (visibility === 'public'
  ? <Tag color="green">Public</Tag>
  : <Tag color="orange">Private</Tag>);

export default VisibilityTag;
