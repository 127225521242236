import React, {
  useState,
  useCallback,
  useRef,
  useEffect,
  ChangeEvent,
} from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Tag, Input, Tooltip, InputRef } from 'antd';

type Props = {
  tags: string[],
  onChange: (tags: string[]) => void,
};

export default function EditableTagGroup({ tags, onChange }: Props) {
  const [inputVisible, setInputVisible] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>('');
  const inputRef = useRef<InputRef>(null);

  const handleClose = useCallback((removedTag: string) => {
    onChange(tags.filter((tag) => tag !== removedTag));
  }, [onChange, tags]);

  const showInput = useCallback(() => {
    setInputVisible(true);
  }, []);

  const handleInputChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.currentTarget.value);
  }, []);

  const handleInputConfirm = useCallback(() => {
    if (inputValue && tags.indexOf(inputValue) === -1) {
      onChange([...tags, inputValue]);
    }

    setInputVisible(false);
    setInputValue('');
  }, [inputValue, onChange, tags]);

  useEffect(() => {
    if (inputVisible && inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputVisible]);

  return (
    <div>
      {tags.map((tag, index) => {
        const isLongTag = tag.length > 23;
        const tagElem = (
          <Tag key={tag} closable={index !== 0} onClose={() => handleClose(tag)}>
            {isLongTag ? `${tag.slice(0, 20)}...` : tag}
          </Tag>
        );
        return isLongTag ? (
          <Tooltip title={tag} key={tag}>
            {tagElem}
          </Tooltip>
        ) : (
          tagElem
        );
      })}
      {inputVisible && (
        <Input
          ref={inputRef}
          type="text"
          size="small"
          style={{ width: 78 }}
          value={inputValue}
          onChange={handleInputChange}
          onBlur={handleInputConfirm}
          onPressEnter={handleInputConfirm}
        />
      )}
      {!inputVisible && (
        <Tag onClick={showInput} style={{ background: '#fff', borderStyle: 'dashed' }}>
          <PlusOutlined />
          {' '}
          Add Tag
        </Tag>
      )}
    </div>
  );
}
