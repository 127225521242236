import React, {
  useEffect, useCallback, useState, useMemo,
} from 'react';
import { BulbOutlined, DeleteOutlined, LinkOutlined } from '@ant-design/icons';
import {
  Checkbox, Form, Input, message, Modal, Select, Button, List, InputNumber, Space, Alert,
} from 'antd';
import { Link } from 'react-router-dom';
import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  getFirestore,
  serverTimestamp,
  setDoc,
  Timestamp,
} from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';

import {
  docTypeOptions, languageOptions, tabOptions, visibilityOptions,
} from '../../../config';
import {
  FolderCondition, FolderConditionKey, FolderObj, SettingsObj,
} from '../../../types';
import ThumbSelector from './ThumbSelector';
import { generateUniqueSlug, getParentFolder } from '../../../utils';
import ErrorBoundary from '../../ErrorBoundary';
import Editor from '../../Editor';


const { Item: FormItem } = Form;

const newFolderIdPrefix = 'new-folder--';
const isNewFolder = (id: string) => id.startsWith(newFolderIdPrefix);

type Props = {
  uneditedFolder: FolderObj | null,
  folders: Array<FolderObj>,
  settings: SettingsObj | null,
  slugs: Array<string>,
  handleEditCancel: () => void,
  isSaving: boolean,
  setIsSaving: (isSaving: boolean) => void,
  handleSavedFolder: (updatedOrNewFolder: FolderObj, isNewFolder: boolean) => void,
};

const generatedTextAlert = <Alert type='info' showIcon message={'The generated text should be considered as only a suggestion to aid you as an editor. Please review the generated text and edit it as necessary 🙏'} />;

const FolderEditModal = ({
  uneditedFolder, folders, slugs, settings, isSaving, handleEditCancel,
  setIsSaving, handleSavedFolder,
} : Props) => {
  const [isThumbSelectorVisible, setIsThumbSelectorVisible] = useState<boolean>(false);
  const [folder, setFolder] = useState<FolderObj | null>(null);
  const [formErrors, setFormErrors] = useState<{ title?: string, slug?: string, intro?: string }>({});
  const [folderConditions, setFolderConditions] = useState<Array<FolderCondition>>([]);
  const [removedConditions, setRemovedConditions] = useState<Array<FolderCondition>>([]);
  const [isLoadingConditions, setLoadingConditions] = useState<boolean>(false);
  const [switchedToManual, setSwitchedToManual] = useState<boolean>(false);
  const [
    conditionsItemsPreview,
    setConditionsItemsPreview,
  ] = useState<null | Array<{ id: string, title: string, tab: string }>>(null);
  const [isLoadingIntroFromAi, setLoadingIntroFromAi] = useState<boolean>(false);
  const [isLoadingDescriptionFromAi, setLoadingDescriptionFromAi] = useState<boolean>(false);
  const [hasGeneratedIntro, setHasGeneratedIntro] = useState<boolean>(false);
  const [hasGeneratedDescription, setHasGeneratedDescription] = useState<boolean>(false);

  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    setFolder(uneditedFolder);
    setFormErrors({});
    setFolderConditions([]);
    setRemovedConditions([]);
    setConditionsItemsPreview(null);
    setLoadingIntroFromAi(false);
    setLoadingDescriptionFromAi(false);
    setHasGeneratedIntro(false);
    setHasGeneratedDescription(false);
    if (uneditedFolder && uneditedFolder.autogenerated && !isNewFolder(uneditedFolder.id)) {
      setLoadingConditions(true);

      const conditionsCollection = collection(doc(collection(getFirestore(), 'folders'), uneditedFolder.id), 'folderConditions');
      getDocs(conditionsCollection).then((querySnapshot) => {
        if (querySnapshot.metadata.fromCache) {
          throw new Error('No Internet!');
        }

        const conditions: Array<FolderCondition> = [];
        querySnapshot.forEach((conditionSnap) => {
          conditions.push({ id: conditionSnap.id, ...conditionSnap.data() as Omit<FolderCondition, 'id'> });
        });

        setFolderConditions(conditions);
        setLoadingConditions(false);
      }).catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
        message.error('Oops! something went wrong while loading the folder.');
      });
    }
  }, [uneditedFolder]);

  const conditionsAreValid = useMemo(() => folderConditions.every(
    (c) => c.key && c.value,
  ), [folderConditions]);

  useEffect(() => {
    if (folderConditions.length && conditionsAreValid) {
      const previewItemsForConditions = httpsCallable<{ conditions: Array<FolderCondition> }, Array<{ id: string, title: string, tab: string }>>(getFunctions(), 'generatedFolders-previewItemsForConditions');
      setConditionsItemsPreview(null);
      previewItemsForConditions({ conditions: folderConditions }).then((response) => {
        const itemsPreview = response.data;
        setConditionsItemsPreview(itemsPreview);
      });
    }
  }, [folderConditions, conditionsAreValid]);

  const validateForm = useCallback((): boolean => {
    if (!folder) {
      return false;
    }

    // reset form errors
    const newFormErrors: { title?: string, slug?: string, intro?: string } = {};

    if (!folder.title) {
      newFormErrors.title = 'Title is required';
    } else if (settings && folder.title.length > settings.maxFolderTitleLength) {
      newFormErrors.title = `Title cannot be longer than ${settings.maxFolderTitleLength} characters`;
    }

    if (!folder.slug) {
      newFormErrors.slug = 'Slug is required';
    } else if (slugs.includes(folder.slug)) {
      newFormErrors.slug = 'Slug must be unique in folders';
    }

    if (folder.description && !folder.intro) {
      newFormErrors.intro = 'Short Intro must be provided when “Description” is provided';
    } else if (folder.intro && settings && folder.intro.length > settings.maxFolderIntroLength) {
      newFormErrors.intro = `Short Intro cannot be longer than ${settings.maxFolderIntroLength} characters (currently ${folder.intro.length})`;
    }

    setFormErrors(newFormErrors);

    return Object.keys(newFormErrors).length === 0;
  }, [folder, slugs, settings]);

  useEffect(() => {
    validateForm();
  }, [folder, validateForm]);

  const handleFieldChange = useCallback((key: string, value: string | boolean) => {
    if (!folder) {
      return;
    }

    const val = key === 'slug' ? generateUniqueSlug(value as string) : value;

    if (key === 'autogenerated') {
      if (value) {
        setFolderConditions((prev) => {
          if (prev.length === 0) {
            return [{ key: 'tab-is', value: folder.tab }];
          }
          return prev;
        });
        setSwitchedToManual(false);
      } else {
        setSwitchedToManual(true);
      }
    }

    setFolder({ ...folder, [key]: val });
  }, [folder]);

  const handleSave = useCallback(() => {
    if (!folder) {
      return;
    }

    if (!validateForm()) {
      return;
    }

    setIsSaving(true);

    const isNew = isNewFolder(folder.id);
    const foldersCollection = collection(getFirestore(), 'folders');
    const ref = isNew ? doc(foldersCollection) : doc(foldersCollection, folder.id);

    // Set database id to new folder
    const folderWithProperId = {
      ...folder,
      id: ref.id,
      updatedAt: serverTimestamp() as Timestamp,
    };

    if (isNew) {
      folderWithProperId.createdAt = serverTimestamp() as Timestamp;
    }

    setDoc(ref, folderWithProperId)
      .then(() => {
        handleSavedFolder(folderWithProperId, isNewFolder(folder.id));
        setFormErrors({});

        // Save folder conditions
        const promises: Array<Promise<void>> = [];
        const conditionsCollection = collection(ref, 'folderConditions');
        removedConditions.forEach((condition) => {
          if (condition.id) {
            promises.push(deleteDoc(doc(conditionsCollection, condition.id)));
          }
        });
        if (folderWithProperId.autogenerated) {
          folderConditions.forEach((condition) => {
            const conditionRef = condition.id
              ? doc(conditionsCollection, condition.id) : doc(conditionsCollection);
            promises.push(setDoc(conditionRef, { key: condition.key, value: condition.value }));
          });
        } else {
          // Remove all conditions
          folderConditions.forEach((condition) => {
            if (condition.id) {
              promises.push(deleteDoc(doc(conditionsCollection, condition.id)));
            }
          });
        }
        if (folderWithProperId.autogenerated) {
          Promise.all(promises)
            .then(() => {
              const updateSingleFolder = httpsCallable<{
                folderId: string
              }>(getFunctions(), 'generatedFolders-updateSingleFolder');
              return updateSingleFolder({ folderId: folderWithProperId.id });
            })
            .catch((err) => {
              // eslint-disable-next-line no-console
              console.error(err);
              message.error('Oops! something went wrong while saving the folder.');
            });
        }
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
        message.error('Oops! something went wrong while saving the folder.');
        setIsSaving(false);
      });
  }, [folderConditions, removedConditions, validateForm, setIsSaving, folder, handleSavedFolder]);

  const handleThumbSelectorOpen = useCallback(() => {
    setIsThumbSelectorVisible(true);
  }, []);

  const handleThumbSelectorSelect = useCallback((thumbnail: string) => {
    handleFieldChange('thumbnail', thumbnail);
    setIsThumbSelectorVisible(false);
  }, [handleFieldChange]);

  const handleThumbSelectorClose = useCallback(() => {
    setIsThumbSelectorVisible(false);
  }, []);

  const handleConditionKeyChange = useCallback((
    condition: FolderCondition,
    key: FolderConditionKey,
  ) => {
    setFolderConditions((prev) => [
      ...prev.map((c) => {
        if (condition === c) {
          const value = key.startsWith('duration') ? '5' : '';
          return { ...c, key, value };
        }
        return c;
      }),
    ]);
  }, []);

  const handleConditionValueChange = useCallback((condition: FolderCondition, value: string) => {
    setFolderConditions((prev) => [
      ...prev.map((c) => (condition === c ? { ...c, value } : c)),
    ]);
  }, []);

  const handleRemoveCondition = useCallback((condition: FolderCondition) => {
    setFolderConditions((prev) => [
      ...prev.filter((c) => condition !== c),
    ]);
    if (condition.id) {
      setRemovedConditions((prev) => [...prev, condition]);
    }
  }, []);

  const handleAddCondition = useCallback(() => {
    if (!folder) {
      return;
    }

    setFolderConditions((prev) => [...prev, { key: 'tab-is', value: folder.tab }]);
  }, [folder]);

  const handleSlugFocus = useCallback(() => {
    if (!folder) {
      return;
    }

    if (!folder.slug && folder.title) {
      setFolder({ ...folder, slug: generateUniqueSlug(folder.title, slugs) });
    }
  }, [folder, slugs]);

  const savingDisabled = Object.keys(formErrors).length > 0;

  const isAncestor = (folderId: string, f: FolderObj): boolean => {
    if (!f.parent) {
      return false;
    }
    const parentFolder = getParentFolder(f, folders);
    return Boolean(f.parent === folderId || (parentFolder && isAncestor(folderId, parentFolder)));
  };

  const generateIntroWithAi = useCallback(async () => {
    const generateText = httpsCallable<{ mode: string, folderId: string }, { prompt: string, result: string }>(getFunctions(), 'generateText');

    if (!folder || isNewFolder(folder.id)) {
      messageApi.error('Please save the folder and add some items before generating an intro.');
      return;
    }

    Modal.confirm({
      title: 'Generate a short intro for the folder using AI.',
      content: <>
        <p>For this to work effectively, you should first fill the folder with some items as the contents will be used
          to generate the intro.</p>
        {folder.intro && <p><strong>The existing intro will be replaced.</strong></p>}
        <p>Are you ready to proceed?</p>
      </>,
      okText: 'Yes',
      onOk: async () => {
        setLoadingIntroFromAi(true);

        const { data: { prompt, result } } = await generateText({ mode: 'folder-intro', folderId: folder.id });
        console.log(`Used prompt: ${prompt}`);
        handleFieldChange('intro', result);

        setLoadingIntroFromAi(false);
        setHasGeneratedIntro(true);
      },
    });
  }, [folder, handleFieldChange, messageApi]);


  const generateDescriptionWithAi = useCallback(async () => {
    const generateText = httpsCallable<{ mode: string, folderId: string }, { prompt: string, result: string }>(getFunctions(), 'generateText');

    if (!folder || isNewFolder(folder.id)) {
      messageApi.error('Please save the folder and add some items before generating a description.');
      return;
    }

    Modal.confirm({
      title: 'Generate a description for the folder using AI.',
      content: <>
        <p>For this to work effectively, you should first fill the folder with some items as the contents will be used
          to generate the description.</p>
        {folder.description && <p><strong>The existing description will be replaced.</strong></p>}
        <p>Are you ready to proceed?</p>
      </>,
      okText: 'Yes',
      onOk: async () => {
        setLoadingDescriptionFromAi(true);

        const { data: { prompt, result } } = await generateText({ mode: 'folder-description', folderId: folder.id });
        console.log(`Used prompt: ${prompt}`);
        handleFieldChange('description', result);

        setLoadingDescriptionFromAi(false);
        setHasGeneratedDescription(true);
      },
    });
  }, [folder, handleFieldChange, messageApi]);

  return (
    <Modal
      title={folder ? `Edit ${folder.title}` : ''}
      open={folder !== null}
      confirmLoading={isSaving}
      okButtonProps={{ disabled: savingDisabled }}
      cancelButtonProps={{ disabled: isSaving }}
      onOk={handleSave}
      onCancel={handleEditCancel}
      width={600}
    >
      {contextHolder}
      <p className="DocsAndFolders__Folders__wrapper__web-app-link">
        {folder && (
          // eslint-disable-next-line react/jsx-no-target-blank
          <a href={`https://web.plumvillage.app/${folder.tab}/${folder.slug}`} target="_blank" rel="noopener">
            <LinkOutlined />
            {' View in web app'}
          </a>
        )}
      </p>
      <FormItem label="Thumbnail">
        <ThumbSelector
          visible={isThumbSelectorVisible}
          thumbnail={folder ? folder.thumbnail : 'default'}
          onOpen={handleThumbSelectorOpen}
          onSelect={handleThumbSelectorSelect}
          onCancel={handleThumbSelectorClose}
        />
      </FormItem>
      <FormItem
        label="Title"
        validateStatus={formErrors.title ? 'error' : undefined}
        help={formErrors.title}
      >
        <Input
          value={folder ? folder.title : ''}
          onChange={(event) => handleFieldChange('title', event.target.value)}
          disabled={isSaving}
        />
      </FormItem>
      <FormItem
        label="Short Intro (optional)"
        validateStatus={formErrors.intro ? 'error' : undefined}
        help={formErrors.intro}
        tooltip="This introduction will be displayed at the top of the folder screen and always be visible."
        extra={
          <Space style={{ margin: 4, flexDirection: 'column-reverse', width: '100%' }}>
            <Button icon={<BulbOutlined />} size={'small'} shape={'round'} onClick={generateIntroWithAi} loading={isLoadingIntroFromAi}>Generate with AI</Button>
            {hasGeneratedIntro && generatedTextAlert}
          </Space>
        }
      >
        <ErrorBoundary>
          <Editor
            value={folder && folder.intro ? folder.intro : ''}
            onChange={(value, delta, source) => {
              // Guard against react-quill modal bug
              // See https://github.com/zenoamaro/react-quill/issues/814#issuecomment-1869666897
              if (source === 'user') {
                handleFieldChange('intro', value);
              }
            }}
            readOnly={isSaving}
          />
        </ErrorBoundary>
      </FormItem>
      <FormItem
        label="Description (optional)"
        tooltip="This description will be displayed at the top of the folder screen under the Short Intro. It is initially hidden until the user taps on 'Read more'."
        extra={
          <Space style={{ margin: 4, flexDirection: 'column-reverse', width: '100%' }}>
            <Button icon={<BulbOutlined />} size={'small'} shape={'round'} onClick={generateDescriptionWithAi} loading={isLoadingDescriptionFromAi}>Generate with AI</Button>
            {hasGeneratedDescription && generatedTextAlert}
          </Space>
        }
      >
        <ErrorBoundary>
          <Editor
            value={folder && folder.description ? folder.description : ''}
            onChange={(value, delta, source) => {
              // Guard against react-quill modal bug
              // See https://github.com/zenoamaro/react-quill/issues/814#issuecomment-1869666897
              if (source === 'user') {
                handleFieldChange('description', value);
              }
            }}
            readOnly={isSaving}
          />
        </ErrorBoundary>
      </FormItem>
      <FormItem
        label="Slug"
        validateStatus={formErrors.slug ? 'error' : undefined}
        help={formErrors.slug}
      >
        <Input
          value={folder ? folder.slug : ''}
          onChange={(event) => handleFieldChange('slug', event.target.value)}
          onFocus={handleSlugFocus}
          disabled={isSaving}
        />
      </FormItem>
      <FormItem
        label="Database ID"
      >
        <Input
          value={folder ? folder.id : ''}
          disabled={true}
        />
      </FormItem>
      <FormItem label="Language">
        <Select
          value={folder ? (folder.lang || 'en') : ''}
          style={{ width: '100%' }}
          disabled={isSaving}
          onChange={(val) => handleFieldChange('lang', val)}
        >
          {Object.keys(languageOptions).map((key) => (
            <Select.Option key={key} value={key}>
              {languageOptions[key as keyof typeof languageOptions]}
            </Select.Option>
          ))}
        </Select>
      </FormItem>
      <FormItem
        label="Parent folder"
      >
        <Select
          value={folder && folder.parent ? folder.parent : ''}
          style={{ width: '100%' }}
          disabled={isSaving}
          onChange={(val) => handleFieldChange('parent', val)}
        >
          <Select.Option key="none" value="">None</Select.Option>
          {folder && folders
            // filter out the active folder and descendents
            // (a folder cannot be parent of itself)
            .filter((f) => folder.id !== f.id && !isAncestor(folder.id, f))
            .map((f) => (
              <Select.Option key={f.id} value={f.id}>
                {getParentFolder(f, folders) && `${getParentFolder(f, folders)?.title} > `}
                {f.title}
              </Select.Option>
            ))}
        </Select>
      </FormItem>
      <FormItem label="Visibility">
        <Select
          value={folder && folder.visibility ? folder.visibility : ''}
          style={{ width: '100%' }}
          disabled={isSaving}
          onChange={(val) => handleFieldChange('visibility', val)}
        >
          {Object.entries(visibilityOptions).map(([key, value]) => (
            <Select.Option key={key} value={key}>{value}</Select.Option>
          ))}
        </Select>
        {folder?.visibility === 'private' && (
          <Alert
            type="warning"
            style={{ margin: 6 }}
            message={
              <>
                This private folder is not visible in the mobile app, but you can
                <a href={`https://web.plumvillage.app/${folder.tab}/${folder.slug}`} target="_blank" rel="noopener">
                  {' preview it in the web app '}
                </a>
                after you
                <a href={'https://web.plumvillage.app/login'} target="_blank" rel="noopener">
                  {' login'}
                </a>
                .
              </>
            }
          />
        )}
      </FormItem>
      <FormItem label="Offline media downloads">
        <Checkbox
          checked={folder ? folder.disableDownloads : false}
          disabled={isSaving}
          onChange={(event) => handleFieldChange('disableDownloads', event.target.checked)}
        >
          Disable offline media downloads for docs in this folder
        </Checkbox>
      </FormItem>
      <FormItem label="Auto-generate">
        <Checkbox
          checked={folder ? folder.autogenerated : false}
          disabled={isSaving}
          onChange={(event) => handleFieldChange('autogenerated', event.target.checked)}
        >
          Automatically populate and update items in this folder based on specified conditions.
        </Checkbox>
        {!folder?.autogenerated && switchedToManual && (
          <Alert
            type="warning"
            style={{ margin: 6 }}
            message="Any items that were automatically placed in the folder will remain unless you manually remove them."
          />
        )}
        {folder && folder.autogenerated && (
          <>
            <List
              size="small"
              header={<div>Conditions</div>}
              style={{ margin: 6 }}
              footer={(
                <div>
                  <Button onClick={handleAddCondition}>Add condition</Button>
                </div>
              )}
              bordered
              dataSource={folderConditions}
              loading={isLoadingConditions}
              renderItem={(condition) => (
                <List.Item>
                  <Space>
                    <Select
                      style={{ minWidth: '15em' }}
                      value={condition.key}
                      onChange={(newKey) => handleConditionKeyChange(condition, newKey)}
                    >
                      <Select.Option value="tab-is">Tab is:</Select.Option>
                      <Select.Option value="type-is">Media type is:</Select.Option>
                      <Select.Option value="duration-greater-than">Duration is greater than:</Select.Option>
                      <Select.Option value="duration-less-than">Duration is less than:</Select.Option>
                      <Select.Option value="subtitle-lang">Has subtitles in language:</Select.Option>
                    </Select>
                    {condition.key === 'tab-is' && (
                      <Select
                        style={{ minWidth: '10em' }}
                        value={condition.value}
                        onChange={(newValue) => handleConditionValueChange(condition, newValue || '')}
                      >
                        {Object.entries(tabOptions).map(([key, tabName]) => (
                          <Select.Option key={key} value={key}>{tabName}</Select.Option>
                        ))}
                      </Select>
                    )}
                    {condition.key === 'type-is' && (
                      <Select
                        style={{ minWidth: '10em' }}
                        value={condition.value}
                        onChange={(newValue) => handleConditionValueChange(condition, newValue)}
                      >
                        {Object.entries(docTypeOptions).map(([key, typeName]) => (
                          <Select.Option key={key} value={key}>{typeName}</Select.Option>
                        ))}
                      </Select>
                    )}
                    {condition.key === 'subtitle-lang' && (
                      <Select
                        style={{ minWidth: '10em' }}
                        value={condition.value}
                        onChange={(newValue) => handleConditionValueChange(condition, newValue)}
                      >
                        {Object.entries(languageOptions).map(([key, languageName]) => (
                          <Select.Option key={key} value={key}>{languageName}</Select.Option>
                        ))}
                      </Select>
                    )}
                    {(condition.key === 'duration-greater-than' || condition.key === 'duration-less-than') && (
                      <>
                        <InputNumber
                          style={{ minWidth: '5em' }}
                          value={condition.value}
                          onChange={(newValue) => handleConditionValueChange(condition, newValue || '')}
                        />
                        {' minutes'}
                      </>
                    )}

                    <Button
                      danger
                      size="small"
                      ghost
                      icon={<DeleteOutlined />}
                      title="Remove"
                      onClick={() => handleRemoveCondition(condition)}
                    />
                  </Space>
                </List.Item>
              )}
            />
            {!conditionsAreValid && (
              <Alert
                type="error"
                style={{ margin: 6 }}
                message="Please add a value for every condition."
              />
            )}
            {conditionsAreValid && folderConditions?.length > 0 && (
              <Space>
                <List
                  size="small"
                  style={{ margin: 18 }}
                  header={(
                    <div>
                      Preview (
                      {conditionsItemsPreview ? (
                        <>
                          {conditionsItemsPreview.length}
                          {' '}
                          items
                        </>
                      ) : 'loading'}
                      )
                    </div>
                  )}
                  bordered
                  dataSource={conditionsItemsPreview || []}
                  loading={!conditionsItemsPreview}
                  pagination={{ defaultPageSize: 10 }}
                  renderItem={(i) => (
                    <List.Item>
                      <Space>
                        <Link to={`/${i.tab}/docs/${i.id}`}>
                          {i.title}
                        </Link>
                      </Space>
                    </List.Item>
                  )}
                />
              </Space>
            )}
          </>
        )}
      </FormItem>
    </Modal>
  );
};

export default FolderEditModal;
