import { useCallback, useEffect, useMemo, useState } from 'react';
import { doc, getDoc, getFirestore, setDoc } from 'firebase/firestore';
import { SettingsObj } from '../types';
import { message } from 'antd';


const useSettings = () => {
  const [isSettingsLoading, setIsLoading] = useState(true);
  const [settings, setSettings] = useState<SettingsObj>({
    maxDocTitleLength: 0,
    maxFolderTitleLength: 0,
    maxFolderIntroLength: 0,
  });
  const [isSavingSettings, setIsSaving] = useState(false);

  const settingsDocRef = useMemo(() => doc(getFirestore(), 'settings', 'settings'), []);

  useEffect(() => {
    getDoc(settingsDocRef)
      .then((settingsSnap) => {
        if (settingsSnap.metadata.fromCache) {
          throw new Error('No Internet!');
        }

        if (settingsSnap.exists()) {
          setSettings(settingsSnap.data() as SettingsObj);
        }
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
        message.error('Oops! something went wrong while loading settings');
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [settingsDocRef]);

  const handleSettingChange = useCallback((name: string, value: string | number | boolean | null) => {
    setSettings(prev => ({ ...prev, [name]: value }));
  }, []);

  const handleSaveSettings = useCallback(() => {
    setIsSaving(true);

    setDoc(settingsDocRef, settings)
      .then(() => {
        message.success('Successfully saved settings');
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
        message.error('Oops! something went wrong while saving the settings');
      })
      .finally(() => {
        setIsSaving(false);
      });
  }, [settings, settingsDocRef]);

  return { isSettingsLoading, settings, handleSettingChange, handleSaveSettings, isSavingSettings };
};

export default useSettings;
