import { Parser } from 'json2csv';
import { message } from 'antd';


export const exportAndDownloadCsv = async (
  name: string, // Without .csv
  header: string[],
  data: string[][],
): Promise<void> => {
  try {
    const parser = new Parser({ header: false });
    const csv = parser.parse([header, ...data]);
    const el = document.createElement('a');
    el.setAttribute('download', `${name}.csv`);
    el.setAttribute('href', `data:text/csv;charset=UTF-8,${encodeURIComponent(csv)}`);
    el.click();
  } catch (error) {
    message.error('Oops! Something went wrong while generating CSV file.');
    // eslint-disable-next-line no-console
    console.error(error);
  }
};
