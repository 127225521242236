import { observable } from 'mobx';

import type { ServiceWorkerStore } from './types';

const storeObj: ServiceWorkerStore = {
  waitingWorker: null,
  setWaitingWorker(waitingWorker) {
    this.waitingWorker = waitingWorker;
  },
};

const serviceWorkerStore: ServiceWorkerStore = observable(storeObj);

export default serviceWorkerStore;
