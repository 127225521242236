import readingTime from 'reading-time';
import slugify from 'slugify';
import deepEqual from 'deep-equal';

import { slugifyConfig } from './config';
import { DocObj, FolderObj } from './types';
import { Duration } from 'luxon';

export const getReadingTime = (text: string | undefined): number => {
  if (!text) {
    return 0;
  }

  const stats = readingTime(text);
  return stats.time > 0 ? Math.round(stats.time / 1000) : 0;
};

export function getDuration(file: Blob, type: 'audio' | 'video'): Promise<number> {
  if (!file) {
    return Promise.resolve(0);
  }

  const src = window.URL.createObjectURL(file);

  const tempEl = document.createElement(type);

  return new Promise((resolve, reject) => {
    try {
      tempEl.addEventListener('loadedmetadata', () => {
        const { duration } = tempEl;
        tempEl.remove();
        return resolve(Math.ceil(duration));
      });
      tempEl.src = src;
    } catch (error) {
      reject(error);
    }
  });
}

// Inspired by https://github.com/Idnan/SoundCloud-Waveform-Generator
export function getAudioWaveformData(file: File, length: number): Promise<string> {
  if (!file || !length || !file.name.toLowerCase().endsWith('.mp3')) {
    return Promise.resolve('');
  }

  const bufferMeasure = (position: number, blockSize: number, data: Float32Array) => {
    let sum = 0.0;
    for (let i = position; i <= (position + blockSize) - 1; i += 1) {
      sum += data[i] ** 2;
    }
    return Math.sqrt(sum / data.length);
  };

  return new Promise((resolve, reject) => {
    try {
      const audioContext = new AudioContext();
      const reader = new FileReader();

      reader.onload = async (event: ProgressEvent<FileReader>) => {
        try {
          if (!(event.target?.result instanceof ArrayBuffer)) {
            throw new Error('Invalid event');
          }
          const buffer: AudioBuffer = await audioContext.decodeAudioData(
            event.target.result as ArrayBuffer,
          );
          const data = buffer.getChannelData(0);
          const blockSize = Math.floor(data.length / length);
          let waveformData = [];

          for (let i = 0; i < length; i += 1) {
            waveformData.push(bufferMeasure(i * blockSize, blockSize, data));
          }

          // apply scale "1" & round waveformData to up to 2 decimal places
          const scale = 1 / Math.max(...waveformData);
          waveformData = waveformData.map((value) => Math.round(value * scale * 100) / 100);

          resolve(waveformData.join(','));
        } catch (error) {
          reject(error);
        }
      };

      reader.readAsArrayBuffer(file);
    } catch (e) {
      reject(e);
    }
  });
}

export function generateUniqueSlug(title: string, slugs: Array<string> = []) {
  let counter = 1;
  let slug = slugify(title, slugifyConfig);

  while (slugs.includes(slug)) {
    const suffix = `-${counter}`;

    if (slug.match(/-\d+$/)) {
      slug = slug.replace(/-\d+$/, suffix);
    } else {
      slug += suffix;
    }

    counter += 1;
  }

  return slug;
}

export function sortCompare(a: { order?: number }, b: { order?: number }) {
  // return items with shortest order value first and those without any order property last
  const order1 = typeof a.order === 'number' ? a.order : Number.MAX_VALUE;
  const order2 = typeof b.order === 'number' ? b.order : Number.MAX_VALUE;
  return order1 - order2;
}

export const sortCompareForFolder = (folderId: string) => (
  a: { folder?: string, order?: number, folders?: { [folderId: string]: { order?: number } } },
  b: { folder?: string, order?: number, folders?: { [folderId: string]: { order?: number } } },
) => {
  // return items with shortest order value first and those without any order property last
  const orderOptional1 = a.folder === folderId ? a.order : a.folders && a.folders[folderId]?.order;
  const order1 = typeof orderOptional1 === 'number' ? orderOptional1 : Number.MAX_VALUE;
  const orderOptional2 = b.folder === folderId ? b.order : b.folders && b.folders[folderId]?.order;
  const order2 = typeof orderOptional2 === 'number' ? orderOptional2 : Number.MAX_VALUE;
  return order1 - order2;
};

export function capitalize(str: string) {
  if (typeof str !== 'string') {
    return '';
  }
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function getChangedKeys(
  o1: { [key: string]: unknown },
  o2: { [key: string]: unknown },
): string[] {
  const keys = Array.from(new Set([...Object.keys(o1), ...Object.keys(o2)]));
  return keys.filter((key) => !deepEqual(o1[key], o2[key]));
}

export function getFolderThumbURL(name: string, size: 'small' | 'normal' = 'normal') {
  const filename = `${name}${size === 'normal' ? '@2x' : ''}.png`;
  return `https://firebasestorage.googleapis.com/v0/b/plum-village.appspot.com/o/thumbs%2F${filename}?alt=media`;
}

export function getVimeoId(hlsUrl: string) {
  let matches = hlsUrl.match(/vimeo.com\/external\/([0-9]+)/);
  if (matches && matches.length === 2) {
    const [, vimeoId] = matches;
    return vimeoId;
  }

  // Parse ID from Bunny CDN playlist URL (eg. https://pvapphls.b-cdn.net/[ID]/playlist.m3u8)
  matches = /b-cdn\.net\/([0-9]+)\/playlist.m3u8/.exec(hlsUrl);
  if (matches && matches[1]) {
    const vimeoID = matches[1];
    return vimeoID;
  }

  // This is a format for download URLs that may be present when others are not
  matches = /progressive_redirect\/playback\/([0-9]+)\/rendition/.exec(hlsUrl);
  if (matches && matches[1]) {
    const vimeoID = matches[1];
    return vimeoID;
  }
  return null;
}

export function getVimeoVisibilityName(visibility: string) {
  switch (visibility) {
    case 'anybody':
      return  'Public';
    case 'disable':
      return 'Hide from Vimeo';
    case 'nobody':
      return 'Private';
    case 'password':
      return 'Password';
    case 'unlisted':
      return 'Unlisted';
    case 'users':
      return 'Vimeo Users Only';
    default:
      return 'Unknown';
  }
}

export function getVimeoVisibilityDescription(visibility: string) {
  switch (visibility) {
    case 'anybody':
      return  '(Anyone can access the video)';
    case 'disable':
      return '(The video is embeddable, but it\'s hidden on Vimeo and can\'t be played)';
    case 'nobody':
      return '(No one except the owner can access the video)';
    case 'password':
      return '(Only those with the password can access the video)';
    case 'unlisted':
      return '(Only those with the private link can access the video)';
    case 'users':
      return '(Only Vimeo members can access the video)';
    default:
      return '';
  }
}

export const getParentFolder = (
  f: FolderObj,
  allFolders: Array<FolderObj>,
): FolderObj | undefined => allFolders.find((f2) => f.parent === f2.id);

export const getParentFolders = (
  folders: Array<FolderObj>,
  folder: FolderObj,
): Array<FolderObj> => {
  if (folder && folder.parent) {
    const parent = folders.find((f) => folder.parent === f.id);
    if (parent) {
      return [...getParentFolders(folders, parent), parent];
    }
  }
  return [];
};

export const getFolderLanguage = (folder: FolderObj, allFolders: Array<FolderObj>) => {
  let lang = folder?.lang;
  if (!lang && folder) {
    // Most folders also do not have a lang property: we need to traverse up the folder tree to find a folder with a language set
    const parentFolders = getParentFolders(allFolders, folder);
    lang = parentFolders.find((f) => f.lang)?.lang;
  }
  return lang || 'en';
};

export const getItemLanguage = (item: DocObj, allFolders: Array<FolderObj>) => {
  const lang = item.lang;
  if (!lang) {
    // Most items do not have a lang property, so we need to find the language from the folder
    const folder = allFolders.find((f) => f.id === item.folder);
    if (folder) {
      return getFolderLanguage(folder, allFolders);
    }
  }
  return lang || 'en';
};

const languageNamesInEnglish = Intl.DisplayNames && new Intl.DisplayNames(['en'], { type: 'language' });
export const languageName = (langCode: string) => {
  try {
    if (langCode.startsWith('en-x-autogen')) {
      return 'English (autogenerated)';
    }
    return languageNamesInEnglish && languageNamesInEnglish.of(langCode);
  } catch (e) {
    console.error(`No language for ${langCode}`);
    return 'Unknown language';
  }
};
export const languageCodeAndName = (langCode: string) => {
  return `${langCode} - ${languageName(langCode)}`;
};

export const renderDuration = (durationSeconds: number) => {
  return Duration.fromObject({
    hours: durationSeconds > 3600 ? 0 : undefined,
    minutes: durationSeconds > 60 ? 0 : undefined,
    seconds: durationSeconds,
  }).normalize().toHuman({ unitDisplay: 'narrow' });
};
