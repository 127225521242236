import React, { useEffect, useState } from 'react';
import {
  Card, Spin, Row, Col, Tag,
} from 'antd';
import dayjs from 'dayjs';
import { Link, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import AuthedPage from '../AuthedPage';
import type { PaymentObj, PaypalTransaction, StripeChargeData } from '../../types';
import {
  getPaymentStatusColour, getPaymentStatusDescription,
  isPayPalAppDonation, loadPayPalPaymentRecord, loadStripePaymentRecord,
} from './paymentHelpers';
import { collection, doc, getDoc, getFirestore } from 'firebase/firestore';

type PaymentParams = {
  paymentId: string,
};

const SinglePayment = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [payment, setPayment] = useState<PaymentObj | null>(null);

  const { paymentId } = useParams<PaymentParams>();

  useEffect(() => {
    if (paymentId) {
      const loadPayment = async () => {
        if (paymentId.startsWith('ch_')) { // Stripe
          const transaction = await getDoc(doc(collection(getFirestore(), 'stripe-charges'), paymentId));
          if (transaction.exists()) {
            const transactionData = transaction.data() as StripeChargeData;
            setPayment(loadStripePaymentRecord(transaction.id, transactionData));
          }
        } else { // PayPal
          const transaction = await getDoc(doc(collection(getFirestore(), 'paypal-transactions'), paymentId));
          if (transaction.exists()) {
            const transactionData = transaction.data() as PaypalTransaction;
            if (isPayPalAppDonation(transactionData)) {
              setPayment(loadPayPalPaymentRecord(transaction.id, transactionData));
            }
          }
        }
        setLoading(false);
      };
      loadPayment();
    }
  }, [paymentId]);

  return (
    <AuthedPage fullwidth requiredPermission="access_donations">
      <Spin spinning={loading} delay={200}>
        {payment ? (
          <Row
            gutter={16}
          >
            <Card title="Status" size="small">
              <Tag color={getPaymentStatusColour(payment.status)}>
                {payment.status}
                {getPaymentStatusDescription(payment.status)}
              </Tag>
            </Card>
            <Col span={12}>
              <Card
                size="small"
                style={{ marginTop: 16 }}
                title={`${payment.platform === 'paypal' ? 'PayPal Transaction' : ''}${payment.platform === 'stripe' ? 'Stripe Charge' : ''} ID`}
              >
                {payment.id}
                {payment.platform === 'stripe' && (
                  <p>
                    <a href={`https://dashboard.stripe.com/payments/${payment.id}`} target="_blank" rel="noopener noreferrer">
                      {'Open in Stripe Dashboard '}
                      <FontAwesomeIcon icon="external-link-alt" />
                    </a>
                  </p>
                )}
                {payment.platform === 'paypal' && (
                  <p>
                    <a href={`https://www.paypal.com/activity/payment/${payment.id}`} target="_blank" rel="noopener noreferrer">
                      {'Open in PayPal '}
                      <FontAwesomeIcon icon="external-link-alt" />
                    </a>
                  </p>
                )}
              </Card>
              <Card title="Date" style={{ marginTop: 16 }} size="small">
                {dayjs(payment.paymentDate).format('YYYY-MM-DD HH:mm')}
              </Card>
              <Card title="Amount" style={{ marginTop: 16 }} size="small">
                {payment.amount}
                <> </>
                {payment.currency}
              </Card>
              <Card title="Payment Description" style={{ marginTop: 16 }} size="small">
                {payment.description}
              </Card>
              <Card title="Platform" style={{ marginTop: 16 }} size="small">
                {payment.platform}
              </Card>
            </Col>
            <Col span={12}>
              <Card title="Donor Name" style={{ marginTop: 16 }} size="small">
                {payment.customerName}
              </Card>
              <Card title="Email" style={{ marginTop: 16 }} size="small">
                <Link to={`/crm/customers/${payment.email}`} title="View donor">
                  {payment.email}
                </Link>
              </Card>
              <Card title="Country" style={{ marginTop: 16 }} size="small">
                {payment.countryCode}
              </Card>
              <Card title="Subscription?" style={{ marginTop: 16 }} size="small">
                {payment.isSubscription ? 'Yes' : 'No'}
              </Card>
            </Col>
          </Row>
        ) : (
          <>{loading ? 'Loading...' : 'Donation not found'}</>
        )}
      </Spin>
    </AuthedPage>
  );
};

export default SinglePayment;
