import React, {
  useCallback, useEffect, useState,
} from 'react';
import {
  List, message,
} from 'antd';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';

import type { FolderObj } from '../../../types';
import Header from './Header';
import FolderListItem from './FolderListItem';
import FolderEditModal from './FolderEditModal';
import { collection, deleteDoc, doc, getDoc, getFirestore } from 'firebase/firestore';
import useSettings from '../../../hooks/useSettings';
import useFolders from '../../../hooks/useFolders';
import { TabbedPageParams } from '../../../types';
import { exportAndDownloadCsv } from '../../../csv';
import { getFolderLanguage, languageName } from '../../../utils';
import { DateTime } from 'luxon';

const newFolderIdPrefix = 'new-folder--';
const getNewFolderId = () => `${newFolderIdPrefix}${(new Date()).getTime()}`;


const Folders = () => {
  const { tab } = useParams<TabbedPageParams>();

  const { folders, isLoadingFolders, reloadFolders, setFolders } = useFolders(tab);
  const [editFolder, setEditFolder] = useState<FolderObj | null>(null);
  const { settings } = useSettings();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [deleting, setDeleting] = useState<Array<string>>([]);

  const [slugs, setSlugs] = useState<Array<string>>([]);

  const [isGeneratingCsv, setIsGeneratingCsv] = useState<boolean>(false);
  const exportCsv = useCallback(() => {
    setIsGeneratingCsv(true);

    const header = [
      'Title',
      'Slug',
      'Tab',
      'Intro',
      'Description',
      'Parent Folder',
      'Thumbnail',
      'Language',
      'Disabled Downloads?',
      'Autogenerated?',
      'Visibility',
      'Created',
      'Updated',
    ];
    const data = folders.map((folder) => [
      folder.title,
      folder.slug,
      folder.tab,
      folder.intro || '',
      folder.description || '',
      folders.find((f) => f.id === folder.parent)?.title || '',
      folder.thumbnail,
      languageName(getFolderLanguage(folder, folders)) || '-',
      folder.disableDownloads ? 'Yes' : 'No',
      folder.autogenerated ? 'Yes' : 'No',
      folder.visibility,
      folder.createdAt ? (DateTime.fromJSDate(folder.createdAt.toDate()).toISO() || '') : '',
      folder.updatedAt ? (DateTime.fromJSDate(folder.updatedAt.toDate()).toISO() || '') : '',
    ] as string[]);

    exportAndDownloadCsv(`pv-app-folders-${tab}-${dayjs().format('YYYY-MM-DD')}`, header, data);
    setIsGeneratingCsv(false);
  }, [tab, folders]);

  useEffect(() => {
    getDoc(doc(collection(getFirestore(), 'transients'), 'folder-slugs')).then((slugsSnapshot) => {
      setSlugs((slugsSnapshot.data() as { list: string[] }).list.filter((slug) => !editFolder || slug !== editFolder.slug));
    });
  }, [editFolder]);

  const addFolder = useCallback(() => {
    if (tab) {
      setEditFolder({
        id: getNewFolderId(),
        title: 'New folder',
        slug: '',
        tab,
        order: folders.length + 1,
        thumbnail: 'default',
        disableDownloads: false,
        visibility: 'public',
      });
    }
  }, [tab, folders]);

  const handleEdit = useCallback((folder: FolderObj) => {
    setEditFolder(folder);
    setSlugs((prevSlugs) => prevSlugs.filter((slug) => slug !== folder.slug));
  }, []);

  const handleDelete = useCallback(async (folder: FolderObj) => {
    setDeleting((prev) => [...prev, folder.id]);

    try {
      await deleteDoc(doc(collection(getFirestore(), 'folders'), folder.id));
      setFolders((prev) => prev.filter(({ id }) => id !== folder.id));
      setSlugs((prev) => prev.filter((slug) => slug !== folder.slug));
    } catch (err) {
      console.error(err);
      message.error('Oops! something went wrong while deleting the folder.');
    }
    setDeleting((prev) => prev.filter((id) => id !== folder.id));
  }, [setFolders]);

  const handleEditCancel = useCallback(() => {
    if (isSaving || !editFolder) {
      return;
    }

    setSlugs((prev) => (editFolder ? [...prev, editFolder.slug] : prev));
    setEditFolder(null);
  }, [isSaving, editFolder]);

  const handleSavedFolder = useCallback((updatedOrNewFolder: FolderObj, isNewFolder: boolean) => {
    setFolders((prevFolders) => {
      if (isNewFolder) {
        return [...prevFolders, updatedOrNewFolder];
      }
      return folders.map(
        (f) => (f.id === updatedOrNewFolder.id ? updatedOrNewFolder : f),
      );
    });
    setEditFolder(null);
    setSlugs((prev) => [...prev, updatedOrNewFolder.slug]);
    setIsSaving(false);
  }, [folders, setFolders]);

  // list sub-folders just after the parent folder
  const expandParents = (arr: Array<FolderObj>, folder: FolderObj): Array<FolderObj> => {
    const subFolders = folders.filter((f) => f.parent === folder.id);
    return [...arr, folder, ...subFolders.reduce(expandParents, [] as Array<FolderObj>)];
  };
  const listItems = folders
    .filter((folder) => !folder.parent)
    .reduce(expandParents, [] as Array<FolderObj>);

  if (!tab) {
    return <>Error - no tab active</>;
  }

  return (
    <div className="DocsAndFolders__Folders__wrapper">
      <Header
        loading={isLoadingFolders}
        tab={tab}
        collection="folders"
        onReload={reloadFolders}
        onAddNew={addFolder}
        onExportCsv={exportCsv}
        isGeneratingCsv={isGeneratingCsv}
      />

      <List
        itemLayout="horizontal"
        dataSource={listItems}
        loading={isLoadingFolders}
        renderItem={(folder) => (
          <FolderListItem
            folder={folder}
            folders={folders}
            isDisabled={editFolder !== null || isSaving}
            isDeleting={deleting.includes(folder.id)}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
          />
        )}
        locale={{ emptyText: 'Sorry, you do not have any folders (yet).' }}
      />

      <FolderEditModal
        uneditedFolder={editFolder}
        slugs={slugs}
        folders={folders}
        settings={settings}
        handleEditCancel={handleEditCancel}
        setIsSaving={setIsSaving}
        handleSavedFolder={handleSavedFolder}
        isSaving={isSaving}
      />
    </div>
  );
};

export default Folders;
