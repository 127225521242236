import { Tabs } from 'antd';
import { Helmet } from 'react-helmet';
import AuthedPage from '../AuthedPage';
import ErrorBoundary from '../ErrorBoundary';
import Metadata from './Metadata';
import {  useNavigate, useParams } from 'react-router-dom';

export const metadataTabkeys = {
  categories: 'categories',
  topics: 'topics',
};

export type MetadataSingular = {
  [Property in keyof typeof metadataTabkeys] : string
};

export type PotentialMetadataConfig = {
  [Property in keyof typeof metadataTabkeys] : {
    available: boolean
  }
};

export const metadataNameSingular: MetadataSingular = {
  categories: 'category',
  topics: 'topic',
};

export const metadataNameOnPVApp: MetadataSingular = {
  categories: 'Type',
  topics: 'Topics',
};

const MetadataWrapper = () => {
  
  const metadataType   = useParams<'metadataType'>().metadataType ?? 'categories';
  const navigate = useNavigate();

  if (!metadataType 
    || (metadataType != 'categories' 
    && metadataType != 'topics' 
    && metadataType != 'categories-potential' 
    && metadataType != 'topics-potential'
    && metadataType != 'categories-approved' 
    && metadataType != 'topics-approved'
    )) {    
    return <>Error - invalid tab</>;
  }

  const parentTab = metadataType.startsWith('categories') ? 'categories' : 'topics';
  const childTab = metadataType.endsWith('potential') ? 'potential' : 'approved';
  
  const tabItems = [
    {
      label: 'Categories',
      key: metadataTabkeys.categories,
      children: (
        <ErrorBoundary>
          <Metadata activeTab={parentTab} activeChildTab={childTab}/>
        </ErrorBoundary>
      ),
    },
    {
      label: 'Topics',
      key: metadataTabkeys.topics,
      children: (
        <ErrorBoundary>
          <Metadata activeTab={parentTab} activeChildTab={childTab}/>
        </ErrorBoundary>
      ),
    },
  ];

  return (
    <AuthedPage fullwidth requiredPermission="edit_content">
      <Helmet>
      <title>Metadata - {parentTab}</title>
      </Helmet>
      <Tabs defaultActiveKey='categories' activeKey={parentTab} onTabClick={(key: string) => {
        if (key !== parentTab) {
          navigate(`/metadata/${key}`);
        }
      }} items={tabItems} />
    </AuthedPage>
  );
};

export default MetadataWrapper;
