import { useEffect, useState } from 'react';
import { message } from 'antd';
import { collection, doc, getFirestore, onSnapshot } from 'firebase/firestore';

function useFolderThumbs() {
  const [thumbs, setThumbs] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const unsubscribe = onSnapshot(doc(collection(getFirestore(), 'folder-thumbs'), 'folder-thumbs'), (snapshot) => {
      setLoading(true);

      if (snapshot.metadata.fromCache) {
        // eslint-disable-next-line no-console
        console.error('No Internet!');
        message.error('Oops! something went wrong while loading folder thumbs');
      }

      const data = snapshot.data();

      if (data && data.names instanceof Array) {
        setThumbs(data.names);
      }

      setLoading(false);
    });

    return unsubscribe;
  }, []);

  return { loading, thumbs };
}

export default useFolderThumbs;
