import React from 'react';
import { createRoot } from 'react-dom/client';

// Configure firebase
import './config/firebase';

import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import serviceWorkerStore from './serviceWorkerStore';

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const container = document.getElementById('root')!;
const root = createRoot(container);
root.render(<App />);

serviceWorkerRegistration.register({
  // show update notice when a new update becomes available
  onUpdate: (
    registration: ServiceWorkerRegistration,
  ) => serviceWorkerStore.setWaitingWorker(registration.waiting),
  // show update notice for pending updates
  onUpdateWaiting: (
    registration: ServiceWorkerRegistration,
  ) => serviceWorkerStore.setWaitingWorker(registration.waiting),
});
