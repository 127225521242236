import React from 'react';
import ReactQuill, { ReactQuillProps } from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import './Editor.scss';

type Props = {
  value: string,
  onChange: ReactQuillProps['onChange'] | ((value: string) => void),
  readOnly: boolean,
};

const modules = {
  toolbar: [
    [{ header: [1, 2, 3, false] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ align: '' }, { align: 'center' }, { align: 'right' }],
    [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
    ['link', 'clean'],
    [{ color: [] }, { background: [] }],
  ],
};

const formats = [
  'blockquote',
  'header',
  'background', 'color',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'list', 'bullet', 'indent', 'align',
  'link',
];

export default function Editor({
  value,
  onChange,
  readOnly,
}: Props) {
  return (
    <ReactQuill
      value={value}
      onChange={onChange}
      readOnly={readOnly}
      modules={modules}
      formats={formats}
    />
  );
}
