import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { message, Button } from 'antd';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faHeadphones, faVideo, faFileAlt, faExternalLinkAlt, faDownload, faBan,
} from '@fortawesome/free-solid-svg-icons';
import { observer } from 'mobx-react-lite';
import 'antd/dist/reset.css';

import AllRoutes from '../AllRoutes';
import serviceWorkerStore from '../serviceWorkerStore';
import './App.scss';
import { getAuth, onAuthStateChanged, User } from 'firebase/auth';

// Add fontawesome icons we use in the app to the library
library.add(faHeadphones, faVideo, faFileAlt, faExternalLinkAlt, faDownload, faBan);

type HideUpdateAvailableMessage = () => void;

const App = observer(() => {
  const [isLoadingAuth, setIsLoadingAuth] = useState(true);
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const hideUpdateAvailableMessageRef = useRef<HideUpdateAvailableMessage | null>(null);

  useEffect(() => {
    const hideMessage = message.loading('Loading...', 0);
    onAuthStateChanged(getAuth(), (user) => {
      hideMessage();
      setCurrentUser(user);
      setIsLoadingAuth(false);
    });
    return () => hideMessage();
  }, []);

  const updateServiceWorker = useCallback(() => {
    if (serviceWorkerStore.waitingWorker) {
      serviceWorkerStore.waitingWorker.postMessage({ type: 'SKIP_WAITING' });
      serviceWorkerStore.waitingWorker.addEventListener('statechange', (e: ServiceWorkerEventMap['statechange']) => {
        if ((e.currentTarget as ServiceWorker).state === 'activated') {
          window.location.reload();
        }
      });
    }
  }, []);

  const closeUpdateAvailableMessage = useCallback(() => {
    if (hideUpdateAvailableMessageRef.current) {
      hideUpdateAvailableMessageRef.current();
      hideUpdateAvailableMessageRef.current = null;
    }
  }, []);

  const updateAvailableMessage = useMemo(() => (
    <>
      <>A new version of Admin is available </>
      <Button
        type="link"
        size="small"
        onClick={updateServiceWorker}
      >
        REFRESH
      </Button>
      &nbsp;
      <Button
        type="link"
        size="small"
        icon={<CloseOutlined />}
        onClick={closeUpdateAvailableMessage}
      />
    </>
  ), [closeUpdateAvailableMessage, updateServiceWorker]);

  // Wait for firebase.auth() object to finish initializing.
  if (currentUser === undefined || isLoadingAuth) {
    return null;
  }

  if (serviceWorkerStore.waitingWorker && !hideUpdateAvailableMessageRef.current) {
    hideUpdateAvailableMessageRef.current = message.info(updateAvailableMessage, 0);
  }

  // We can now safely use auth.currentUser in the app
  // which otherwise might also be null because the auth object has not finished initializing.
  return <AllRoutes />;
});

export default App;
