import React, {
  useCallback, useMemo,
} from 'react';
import {
  Alert, Form, Select,
} from 'antd';

import { DocObj, TabValue } from '../../types';
import { languageOptions } from '../../config';
import useFolders from '../../hooks/useFolders';

const { Item: FormItem } = Form;

type Props = {
  doc?: DocObj | null;
  tab: TabValue,
  isDisabled: boolean;
  handleChange: (key: string, value: string) => void;
  updateFolders: (folders: { [folderId: string]: { order?: number } }) => void;
  formErrors: { secondaryFolders?: string },
};

const FolderFormItems = ({
  doc, isDisabled, handleChange, tab, updateFolders, formErrors,
}: Props) => {
  const { folders } = useFolders(tab);

  const handleSecondaryFoldersChange = useCallback((folderIds: Array<string>) => {
    if (!doc) {
      return;
    }

    const oldFolders: { [folderId: string]: { order?: number } } = doc.folders || {};

    const folderOrders = folderIds.reduce((dict, fid) => {
      const oldOrder = oldFolders[fid]?.order;
      // eslint-disable-next-line no-param-reassign
      dict[fid] = oldOrder !== undefined ? { order: oldOrder } : {};
      return dict;
    }, {} as { [folderId: string]: { order?: number } });

    updateFolders(folderOrders);
  }, [doc, updateFolders]);

  const folderOptions = useMemo(() => {
    return folders.map((folder) => ({
      value: folder.id, label: folder.title, lang: folder.lang, autogenerated: folder.autogenerated,
    }));
  }, [folders]);

  const autogeneratedFolderOptions = useMemo(
    () => folderOptions.filter((f) => f.autogenerated),
    [folderOptions],
  );

  const selectedSecondaryFolders = useMemo(() => {
    // All secondary folders except for autogenerated ones
    const initial = Object.keys(doc?.folders || {});
    // Exclude any autogenerated folders
    const autogeneratedIds = autogeneratedFolderOptions.map((f) => f.value);
    return initial.filter((f) => !autogeneratedIds.includes(f));
  }, [doc, autogeneratedFolderOptions]);

  const autogeneratedFoldersContainingItem = useMemo(() => {
    // All secondary folders that are autogenerated ones
    const initial = Object.keys(doc?.folders || {});
    return autogeneratedFolderOptions.filter((f) => initial.includes(f.value));
  }, [doc, autogeneratedFolderOptions]);

  return (
    <>
      <FormItem
        label="Primary Folder"
        tooltip="Required. The folder which this items will appear in. This primary folder will be shown in the navigational breadcrumbs for this item."
      >
        <Select
          value={(doc && doc.folder) ||  ''}
          style={{ width: '100%' }}
          placeholder="Select a folder"
          onChange={(value) => handleChange('folder', value)}
          disabled={isDisabled}
        >
          {Object.entries(languageOptions).map(([langCode, langName]) => (
            <Select.OptGroup label={langName} key={langCode}>
              {folderOptions
                .filter(({ lang, autogenerated }) => (langCode === lang || (langCode === 'en' && !lang)) && !autogenerated)
                .map(({ value, label }) => (
                  <Select.Option key={value} value={value}>{label}</Select.Option>
                ))}
            </Select.OptGroup>
          ))}
        </Select>
      </FormItem>

      <FormItem
        label="Additional Folder(s)"
        tooltip="Optional, defines additional folders that this item should appear in."
        validateStatus={formErrors.secondaryFolders && 'error'}
        help={formErrors.secondaryFolders}
      >
        <Select
          mode="multiple"
          value={selectedSecondaryFolders}
          style={{ width: '100%', minWidth: '200px' }}
          placeholder="Select folders"
          onChange={(value) => handleSecondaryFoldersChange(value)}
          disabled={isDisabled}
        >
          {Object.entries(languageOptions).map(([langCode, langName]) => (
            <Select.OptGroup label={langName} key={langCode}>
              {folderOptions
                .filter(({ lang, autogenerated }) => (langCode === lang || (langCode === 'en' && !lang)) && !autogenerated)
                .map(({ value, label }) => (
                  <Select.Option key={value} value={value}>{label}</Select.Option>
                ))}
            </Select.OptGroup>
          ))}
        </Select>
      </FormItem>

      {autogeneratedFoldersContainingItem.length > 0 && (
        <Alert
          type="warning"
          style={{ flexDirection: 'column', margin: 6 }}
          message={`Also appears in the following automated folders: ${autogeneratedFoldersContainingItem.map((f) => f.label).join(', ')}`}
        />
      )}
    </>
  );
};

export default FolderFormItems;
