import { DeleteOutlined, EditOutlined, PlusOutlined, ReloadOutlined } from '@ant-design/icons';
import { Button, Divider, InputRef, Popconfirm, Table, message } from 'antd';
import { collection, deleteDoc, doc, getDocs, getFirestore, query } from 'firebase/firestore';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useSearchFilter from '../../hooks/useSearchFilter';
import { metadataTabkeys } from './MetadataWrapper';
import { MetadataType, languageNames, supportedLanguageCodes } from './Metadata';


const getEditUrl = (activeTab: keyof typeof metadataTabkeys, item: MetadataType) => {
  return `/metadata/${activeTab}/${item.id}`;
};

const MetadataApproved = ({ activeTab } : { activeTab : keyof typeof metadataTabkeys }) => {
  
  const [loading, setLoading] = useState<boolean>(true);
  const [metadatas, setMetadatas] = useState<Array<MetadataType>>([]);
  const [deleting, setDeleting] = useState<Array<string>>([]);
  const navigate = useNavigate();
  const searchInput = useRef<InputRef>(null);
  const { filterDropdown, renderHighlight, searchText, searchFilterIcon, onFilterDropdownOpenChange } = useSearchFilter();

  const columnRenderer = useCallback((value: string): JSX.Element => {
    const content = renderHighlight(value);
    return (
      <span style={{ whiteSpace: 'nowrap' }}>
      {content}
    </span>
    );
  }, [renderHighlight]);

  const handleDelete = useCallback(async (item: MetadataType) => {
    setDeleting([...deleting, item.id]);

    try {
      await deleteDoc(doc(collection(getFirestore(), activeTab), item.id));
      setMetadatas((prevDocs) => prevDocs.filter(({ id }) => id !== item.id));
      setDeleting((prevDeleting) => prevDeleting.filter((id) => id !== item.id));
    } catch (err) {
      console.error(err);
      message.error('Oops! something went wrong while deleting doc.');
      setDeleting((prevDeleting) => prevDeleting.filter((id) => id !== item.id));
    }
  }, [deleting, activeTab]);

  const reloadMetadatas = useCallback(async () => {

    setLoading(true);

    const metadataSnapshot = await getDocs(query(collection(getFirestore(), activeTab)));

    if (metadataSnapshot.metadata.fromCache) {
      throw new Error('No Internet!');
    }
    const loadingMetadatas: Array<MetadataType> = [];

    metadataSnapshot.forEach((item) => {
      
      const data = item.data() as Omit<MetadataType, 'id'>;
      const metadataId = item.id;
      const metadata: MetadataType = {
        id: metadataId,
        ...data,
      };
      loadingMetadatas.push(metadata);
    });
    
    setMetadatas(loadingMetadatas);
    setLoading(false);
  }, [activeTab]);

  useEffect(() => {
    reloadMetadatas();
  }, [reloadMetadatas]);

  return (
  <>
  <div className="DocsAndFoldersHeader__wrapper">
  <Button
      type="primary"
      ghost
      loading={loading}
      icon={<ReloadOutlined />}
      onClick={reloadMetadatas}
    >
      Reload
    </Button>
    <Button
      type="primary"
      onClick={() => navigate(`/metadata/${activeTab}/new`)}
      disabled={loading}
      icon={<PlusOutlined />}
    >
      Add new
    </Button>
  </div>
  <Table
    loading={loading}
    dataSource={metadatas}
    rowKey={(item: MetadataType) => item.en}
    locale={{ emptyText: searchText ? 'Nothing found matching your search' : `Sorry, you do not have any ${activeTab} (yet).` }}
    scroll={{ x: true }}
  >
    <Table.Column
      title="#"
      dataIndex=""
      width={'5.5%'}
      render={(text: string, item: MetadataType) => (
        <span style={{ whiteSpace: 'nowrap' }}>
          {metadatas.indexOf(item) + 1}
        </span>
      )}
    />
    {
      supportedLanguageCodes.map(code => 
        <Table.Column
        title={languageNames[code]}
        dataIndex=""
        width={'13.5%'}
        filterDropdown={filterDropdown('title', searchInput)}
        filterIcon={searchFilterIcon}
        onFilter={(value, item: MetadataType) => item[code]?.toLowerCase().includes((value as string).toLowerCase()) ?? false}
        onFilterDropdownOpenChange={onFilterDropdownOpenChange(searchInput)}
        render={(item: MetadataType) => 
          item[code] ? columnRenderer(item[code] ?? '') : <></>
        }
      />,
      )
    }
    <Table.Column
        title="Actions"
        key="actions"
        width={'13.5%'}
        render={(text: string, item: MetadataType) => {
          const { en, id } = item;
          const isBeingDeleted = deleting.includes(id);
          const confirmTitle = (
            <span>
            {'Are you sure you want to delete '}
            <strong>{en}</strong>
            ?
            </span>
          );

          return (
            <div style={{ whiteSpace: 'nowrap' }}>
                <Button
                    type="primary"
                    size="small"
                    icon={<EditOutlined />}
                    onClick={() => navigate(getEditUrl(activeTab, item))}
                    disabled={isBeingDeleted}
                />
                <Divider type="vertical" />
                <Popconfirm
                    title={confirmTitle}
                    onConfirm={() => handleDelete(item)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button
                    danger
                    size="small"
                    ghost
                    icon={<DeleteOutlined />}
                    loading={isBeingDeleted}
                    />
                </Popconfirm>
            </div>
          );
        }}
    />
  </Table>
  </>
  );
};

export default MetadataApproved;