import React from 'react';
import { Modal, Spin } from 'antd';
import styled from 'styled-components';

import useFolderThumbs from '../../../hooks/useFolderThumbs';
import { getFolderThumbURL } from '../../../utils';

type Props = {
  visible: boolean,
  thumbnail: string,
  onOpen: () => void,
  onSelect: (thumbnail: string) => void,
  onCancel: () => void,
};

const Img = styled.img`
  display: block;
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
`;

const Thumbnail = styled.button`
  width: 100px;
  height: 100px;
  margin: 15px;
  padding: 0;
  background: none;
  border: 0;
  outline: none;
  box-shadow: none;
  cursor: pointer;
`;

const OverlayButton = styled(Thumbnail)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  color: #fff;
  background: rgba(0, 0, 0, 0.5);
`;

const ThumbPreview = styled.div`
  position: relative;
  width: 100px;
  height: 100px;

  ${OverlayButton} {
    display: none;
  }

  &:hover ${OverlayButton} {
    display: block;
  }
`;

const Thumbnails = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const ThumbSelector = (props: Props) => {
  const {
    visible, thumbnail, onOpen, onSelect, onCancel,
  } = props;

  const { loading, thumbs } = useFolderThumbs();

  const thumbnailsMarkup = thumbs
    .filter((thumb) => thumb !== 'default')
    .map((thumb) => (
      <Thumbnail
        key={thumb}
        onClick={() => onSelect(thumb)}
      >
        <Img src={getFolderThumbURL(thumb)} alt="" />
      </Thumbnail>
    ));

  return (
    <>
      <ThumbPreview>
        <Img src={getFolderThumbURL(thumbnail)} alt="" />
        <OverlayButton onClick={onOpen}>Change</OverlayButton>
      </ThumbPreview>
      <Modal
        title="Select thumbnail"
        open={visible}
        onCancel={onCancel}
        footer={null}
        width={700}
      >
        <Spin spinning={loading}>
          <Thumbnails>
            {thumbnailsMarkup}
          </Thumbnails>
        </Spin>
      </Modal>
    </>
  );
};

export default ThumbSelector;
