import { useCallback, useState } from 'react';
import { FileOption } from '../components/DocEdit/formTypes';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { DocType, VimeoVideoResult } from '../types';
import { collection, getDocs, getFirestore } from 'firebase/firestore';
import { message } from 'antd';

type FileOptions = {
  audio: Array<FileOption>;
  video: Array<FileOption>;
};

const useMediaFileOptions = () => {
  const [fileOptions, setFileOptions] = useState<FileOptions>(({ audio: [], video: [] }));
  const [isLoadingFiles, setIsLoadingFiles] = useState<boolean>(false);
  const [initializedLoadingAudio, setIsInitializedLoadingAudio] = useState<boolean>(false);
  const [initializedLoadingVideo, setIsInitializedLoadingVideo] = useState<boolean>(false);

  const loadFiles = useCallback(async (docType: DocType) => {
    setIsLoadingFiles(true);

    try {
      if (docType === 'video') {
        setIsInitializedLoadingVideo(true);

        const getVimeoVideos = httpsCallable<unknown, Array<VimeoVideoResult>>(getFunctions(), 'getVimeoVideos');
        // Start loading Vimeo videos asynchronously as it will likely take longer
        const response = await getVimeoVideos();
        const vimeoFileOptions: FileOption[] = response.data
          .map((video: VimeoVideoResult) => ({
            value: video.downloadURL || '',
            label: video.title,
            duration: video.duration,
            hlsURL: video.hlsURL,
            vimeoID: video.vimeoID,
            downloadURL: video.downloadURL,
            description: video.description,
            visibility: video.visibility,
            rating: video.rating,
            language: video.language,
          }));
        setFileOptions(({ audio }) => ({
          audio,
          video: [...vimeoFileOptions],
        }));
      } else {
        setIsInitializedLoadingAudio(true);

        // fetch audio & legacy video options
        const querySnapshot = await getDocs(
          collection(getFirestore(), 'files'),
        );

        if (querySnapshot.metadata.fromCache) {
          throw new Error('No Internet!');
        }

        const audioFileOptions: FileOption[] = [];

        querySnapshot.forEach((file) => {
          const {
            name, url, cdnUrl, title, duration, waveformData,
          } = file.data();
          const type = name.toLowerCase().endsWith('.mp3') ? 'audio' : 'video';
          if (type === 'audio') {
            audioFileOptions.push({
              value: cdnUrl || url,
              label: title,
              duration,
              waveformData,
            });
          }
        });

        setFileOptions(({ video }) => ({
          audio: [...audioFileOptions],
          video,
        }));
      }
      setIsLoadingFiles(false);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      message.error(`Oops! something went wrong while loading ${docType} options`);
      setIsLoadingFiles(false);
    }
  }, []);

  return { fileOptions, isLoadingFiles, loadFiles, initializedLoadingAudio, initializedLoadingVideo };
};

export default useMediaFileOptions;
