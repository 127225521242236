import React, { useCallback, useMemo, useState } from 'react';
import { getStorage, ref, uploadBytesResumable, getDownloadURL, deleteObject, UploadTaskSnapshot, UploadTask } from 'firebase/storage';
import { getFirestore, collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { v4 as uuidv4 } from 'uuid';
import {
  CloudUploadOutlined, StopOutlined, CaretRightOutlined, PauseOutlined,
} from '@ant-design/icons';
import {
  List, Progress, Popconfirm, Button,
} from 'antd';
import Dropzone from 'react-dropzone';
import { ProgressProps } from 'antd/lib/progress/progress';

import { getDuration, getAudioWaveformData } from '../../utils';

const generateFileStorageName = (name: string) => {
  const guid = uuidv4();
  const extension = name.split('.').pop();
  return `${guid}.${extension?.toLowerCase()}`;
};

type UploadType = {
  title: string,
  filename: string,
  progress: number,
  paused: boolean,
  complete: boolean,
  error: boolean,
  task: UploadTask,
};

const MediaUpload = () => {
  const [uploads, setUploads] = useState<Array<UploadType>>([]);

  // Comment from 2020:
  // TODO: change media directory to "media"
  // and move all files in "/temp" to "media"
  // and update their download URLs in the docs
  const storageMediaRef = useMemo(() => ref(getStorage(), '/temp'), []);

  const handleUploadStateChange = useCallback((snapshot: UploadTaskSnapshot, filename: string) => {
    const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
    let paused = false;
    if (snapshot.state === 'paused') {
      paused = true;
    }

    setUploads(prevUploads => prevUploads.map(upload => {
      if (upload.filename === filename) {
        return { ...upload, progress, paused };
      }

      return upload;
    }));
  }, []);

  const handleUploadError = useCallback((filename: string, error: Error) => {
    setUploads(prevUploads => prevUploads.map((upload) => {
      if (upload.filename === filename) {
        return { ...upload, error: true };
      }
      return upload;
    }));
    // eslint-disable-next-line no-console
    console.error(error.message);
  }, []);

  const handleUploadSuccess = useCallback(async (file: File, filename: string) => {
    const fileRef = ref(storageMediaRef, filename);

    try {
      const url = await getDownloadURL(fileRef);
      const type = filename.toLowerCase().endsWith('.mp3') ? 'audio' : 'video';
      let duration;
      let waveformData;

      if (type === 'audio') {
        [duration, waveformData] = await Promise.all([
          getDuration(file, type),
          getAudioWaveformData(file, 100),
        ]);
      } else {
        duration = await getDuration(file, type);
      }

      await addDoc(collection(getFirestore(), ('files')), {
        title: file.name,
        name: filename,
        url,
        duration,
        waveformData: waveformData || null,
        uploadedAt: serverTimestamp(),
      });

      setUploads((prevUploads) => prevUploads.map((u) => (u.filename === filename ? { ...u, complete: true } : u)));
    } catch (err) {
      setUploads((prevUploads) => prevUploads.map((u) => (u.filename === filename ? { ...u, error: true } : u)));

      // eslint-disable-next-line no-console
      console.error(`
        An error occurred while generating and saving uploaded file's metadata to the database.
          File: ${file.name}
          Error: ${err.message}
        Deleting uploaded file...
      `);
      // delete uploaded file from firebase storage
      deleteObject(fileRef).then(() => {
        // eslint-disable-next-line no-console
        console.log('Successfully deleted uploaded file.');
      }).catch(() => {
        // eslint-disable-next-line no-console
        console.error('Another error occurred while deleting the uploaded file.');
      });
    }
  }, [storageMediaRef]);

  const handleDrop = useCallback((files: Array<File>) => {
    const newUploads: Array<UploadType> = [];

    files.forEach((file) => {
      const storageFilename = generateFileStorageName(file.name);

      const uploadTask = uploadBytesResumable(ref(storageMediaRef, storageFilename), file);

      uploadTask.on(
        'state_changed',
        (snapshot) => handleUploadStateChange(snapshot, storageFilename),
        (error) => handleUploadError(storageFilename, error),
        () => handleUploadSuccess(file, storageFilename),
      );

      newUploads.push({
        title: file.name,
        filename: storageFilename,
        progress: 0,
        paused: false,
        complete: false,
        error: false,
        task: uploadTask,
      });
    });

    setUploads(prevUploads => [...prevUploads, ...newUploads]);
  }, [handleUploadStateChange, handleUploadError, handleUploadSuccess, storageMediaRef]);

  const handleUploadToggle = useCallback((upload: UploadType) => {
    if (upload.paused) {
      upload.task.resume();
    } else {
      upload.task.pause();
    }
  }, []);

  const handleUploadCancel = useCallback((upload: UploadType) => {
    upload.task.cancel();

    setUploads((prevUploads) => prevUploads.filter((u) => u.filename !== upload.filename));
  }, []);

  const renderListItem = useCallback((upload: UploadType) => {
    const {
      filename, title, error, progress, paused, complete,
    } = upload;

    const savingToDB = progress === 100 && !complete;

    let status: ProgressProps['status'] = 'active';
    if (error) {
      status = 'exception';
    } else if (complete) {
      status = 'success';
    } else if (paused) {
      status = 'normal';
    }

    const confirmTitle = (
      <span>
        {'Are you sure you want to cancel uploading '}
        <strong>{title}</strong>
        ?
      </span>
    );

    const actions = [
      <Button
        type="primary"
        size="small"
        icon={paused ? <CaretRightOutlined /> : <PauseOutlined />}
        aria-label={upload.paused ? 'resume upload' : 'pause upload'}
        onClick={() => handleUploadToggle(upload)}
        loading={savingToDB}
        disabled={complete}
      />,
      <Popconfirm title={confirmTitle} onConfirm={() => handleUploadCancel(upload)} okText="Yes" cancelText="No">
        <Button
          danger
          size="small"
          ghost
          icon={<StopOutlined />}
          disabled={complete}
        />
      </Popconfirm>,
    ];

    return (
      <List.Item
        key={filename}
        actions={actions}
      >
        <List.Item.Meta
          title={title}
          description={(
            <Progress percent={progress} size="small" status={status} />
          )}
        />
      </List.Item>
    );
  }, [handleUploadToggle, handleUploadCancel]);

  const renderUploadingFiles = useCallback(() => {
    if (uploads.length === 0) {
      return null;
    }

    return (
      <div>
        <p>
          <strong>
            {`Uploading ${uploads.length} ${uploads.length === 1 ? 'file' : 'files'}`}
          </strong>
        </p>
        <List
          itemLayout="horizontal"
          dataSource={uploads}
          renderItem={renderListItem}
        />
      </div>
    );
  }, [uploads, renderListItem]);

  return (
    <>
      <Dropzone onDrop={handleDrop} accept={{ 'audio/mpeg': ['.mp3'] }}>
        {({ getRootProps, getInputProps }) => (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <div {...getRootProps({ className: 'media-dropzone' })}>
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            <input {...getInputProps()} />
            <p className="media-upload-drag-icon">
              <CloudUploadOutlined />
            </p>
            <p className="media-upload-text">Click or drag files to this area to upload</p>
            <p className="media-upload-hint">
              {'Only '}
              <code>.mp3</code>
              {' files are accepted.'}
            </p>
          </div>
        )}
      </Dropzone>
      {renderUploadingFiles()}
    </>
  );
};

export default MediaUpload;
