import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { Menu } from 'antd';

import type { MenuItem } from '../../types';
import { menuItems } from '../../config';

const MainMenu = () => {
  const location = useLocation();

  const items = menuItems.map((menuItem: MenuItem) => {
    const { slug, menuTitle } = menuItem;
    return {
      label: (
        <NavLink to={`/${slug}`}>{menuTitle}</NavLink>
      ),
      key: slug,
    };
  });

  const firstSegment = location.pathname.split('/').filter(s => s).shift();

  return (
    <Menu theme="dark" mode="inline" items={items} defaultSelectedKeys={firstSegment ? [firstSegment] : []} />
  );
};

export default MainMenu;
