import React, { useLayoutEffect, useState } from 'react';
import {
  BrowserRouter, Routes, Route, Navigate,
} from 'react-router-dom';
import { Helmet } from 'react-helmet';
import DocsAndFolders from './components/DocsAndFolders';
import DocEdit from './components/DocEdit';
import Audio from './components/Audio';
import Video from './components/Video';
import CRM, { SinglePayment, SingleCustomer } from './components/CRM';
import Settings from './components/Settings';
import LinkGenerator from './components/LinkGenerator';
import Activity from './components/Activity';
import Users from './components/Users';
import SyncPodcasts from './components/SyncPodcasts';
import FolderThumbs from './components/FolderThumbs';
import Login from './components/Login';
import NotFound from './components/NotFound';
import Subtitles from './components/Subtitles';
import RequireAuth from './components/RequireAuth';
import OnlyUnauthenticated from './components/OnlyUnauthenticated';
import MetadataWrapper from './components/Metadata/MetadataWrapper';
import MetadataEdit from './components/Metadata/MetadataEdit';
import MetadataPotentialEdit from './components/Metadata/MetadataPotentialEdit';

const AllRoutes = () => {
  const [initialPage, setInitialPage] = useState<string>('/resources');

  useLayoutEffect(() => {
    if (window.location.pathname !== '/login' && window.location.pathname !== '/') {
      setInitialPage(window.location.pathname);
    }
    // Only run on first load:
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialPage]);

  return (
    <BrowserRouter>
      <Helmet defaultTitle="Plum Village Admin v2" titleTemplate="%s | Plum Village Admin v2" />
      <Routes>
        <Route path="/" element={<Navigate to={initialPage} />} />
        <Route path="/:tab/docs/:docId" element={<RequireAuth><DocEdit /></RequireAuth>} />
        {/* meditations / talks / resources */}
        <Route path="/:tab" element={<RequireAuth><DocsAndFolders /></RequireAuth>} />
        <Route path="/media" element={<RequireAuth><Audio /></RequireAuth>} />
        <Route path="/video" element={<RequireAuth><Video /></RequireAuth>} />
        <Route path="/subtitles" element={<RequireAuth><Subtitles /></RequireAuth>} />
        <Route path="/metadata/:metadataType?" element={<RequireAuth><MetadataWrapper /></RequireAuth>} />
        <Route path="/metadata/:metadataType/:metadataId" element={<RequireAuth><MetadataEdit /></RequireAuth>} />
        <Route path="/metadata/:metadataType/potential/:metadataId" element={<RequireAuth><MetadataPotentialEdit /></RequireAuth>} />
        <Route path="/settings" element={<RequireAuth><Settings /></RequireAuth>} />
        <Route path="/crm/payments/:paymentId" element={<RequireAuth><SinglePayment /></RequireAuth>} />
        <Route path="/crm/customers/:customerId" element={<RequireAuth><SingleCustomer /></RequireAuth>} />
        <Route path="/crm" element={<RequireAuth><CRM /></RequireAuth>} />
        <Route path="/link-generator" element={<RequireAuth><LinkGenerator /></RequireAuth>} />
        <Route path="/activity" element={<RequireAuth><Activity /></RequireAuth>} />
        <Route path="/sync-podcasts" element={<RequireAuth><SyncPodcasts /></RequireAuth>} />
        <Route path="/folder-thumbs" element={<RequireAuth><FolderThumbs /></RequireAuth>} />
        <Route path="/users" element={<RequireAuth><Users /></RequireAuth>} />
        <Route path="/login" element={<OnlyUnauthenticated><Login /></OnlyUnauthenticated>} />

        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AllRoutes;
