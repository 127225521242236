import React, { useContext, useMemo } from 'react';
import {
  Avatar, Button, List, Popconfirm, Tag,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { DeleteOutlined, EditOutlined, LinkOutlined } from '@ant-design/icons';

import { getFolderThumbURL, getParentFolders } from '../../../utils';
import VisibilityTag from '../../VisibilityTag';
import { languageOptions } from '../../../config';
import { FolderObj } from '../../../types';
import { DocsFilterContext } from '../DocsAndFolders';

const FolderTitle = styled.div`
  display: flex;
  align-items: center;
  > * + * {
    margin-left: 8px !important;
  }
`;

type Props = {
  folder: FolderObj,
  folders: Array<FolderObj>,
  isDisabled: boolean,
  isDeleting: boolean,
  handleEdit: (folder: FolderObj) => void,
  handleDelete: (folder: FolderObj) => void,
};

const FolderListItem = ({
  folder, folders, isDisabled, isDeleting, handleEdit, handleDelete,
} : Props) => {
  const {
    title, slug, thumbnail, disableDownloads, visibility, lang,
  } = folder;

  const confirmTitle = (
    <span>
      {'Are you sure you want to delete '}
      <strong>{title}</strong>
      ?
    </span>
  );

  const actions = [
    <Button
      type="primary"
      size="small"
      icon={<EditOutlined />}
      onClick={() => handleEdit(folder)}
      disabled={isDisabled}
    />,
    <Popconfirm
      title={confirmTitle}
      onConfirm={() => handleDelete(folder)}
      okText="Yes"
      cancelText="No"
    >
      <Button
        danger
        size="small"
        ghost
        icon={<DeleteOutlined />}
        disabled={isDisabled}
        loading={isDeleting}
      />
    </Popconfirm>,
  ];

  const numberOfAncestors = useMemo(() => {
    return getParentFolders(folders, folder).length;
  }, [folder, folders]);

  const hasChildren = useMemo(() => {
    return folders.some((f) => f.parent === folder.id);
  }, [folder, folders]);

  const { setFilterFolder, setDocsOrFolders } = useContext(DocsFilterContext);

  return (
    <List.Item actions={actions} style={{ paddingLeft: 48 * numberOfAncestors }}>
      <List.Item.Meta
        avatar={<Avatar src={getFolderThumbURL(thumbnail || 'default', 'small')} />}
        title={(
          <FolderTitle>
            <span title={`ID: ${folder.id}`}>{title}</span>
            <VisibilityTag visibility={visibility} />
            {folder.autogenerated && <Tag>Automated</Tag>}
            <Tag color="blue">{languageOptions[lang as keyof typeof languageOptions || 'en']}</Tag>
            {disableDownloads && (
              <span className="fa-layers fa-fw fa-lg">
                <FontAwesomeIcon icon="download" transform="shrink-6" />
                <FontAwesomeIcon icon="ban" color="rgba(255, 99, 71, 0.8)" />
              </span>
            )}
          </FolderTitle>
        )}
        description={
          <>
            {slug}
            {' '}
            <a href={`https://web.plumvillage.app/${folder.tab}/${folder.slug}`} target="_blank" rel="noopener">
              <LinkOutlined />
              {' View in web app'}
            </a>
            {' '}
            {!hasChildren && <Button size='small' onClick={() => {
              setFilterFolder(folder.id);
              setDocsOrFolders('docs');
            }}>View docs</Button>}
          </>
        }
      />
    </List.Item>
  );
};

export default FolderListItem;
