import React, {
  useCallback, useState, useEffect, useRef,
} from 'react';
import { collection, deleteDoc, doc, getDocs, getFirestore, Timestamp } from 'firebase/firestore';
import { deleteObject, getStorage, ref } from 'firebase/storage';
import {
  CaretRightOutlined, DeleteOutlined, EditOutlined, ReloadOutlined,
} from '@ant-design/icons';
import {
  Button, Table, Divider, Popconfirm, message, InputRef,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateTime } from 'luxon';

import type { FileObj } from '../../types';
import FileEditModal from './FileEditModal';
import useSearchFilter from '../../hooks/useSearchFilter';
import { renderDuration } from '../../utils';

const { Column } = Table;

const Files = () => {
  const [files, setFiles] = useState<Array<FileObj>>([]);
  const [editFile, setEditFile] = useState<FileObj | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [deleting, setDeleting] = useState<Array<string>>([]);
  const searchInput = useRef<InputRef>(null);

  const { filterDropdown, searchFilterIcon, onFilterDropdownOpenChange, renderHighlight } = useSearchFilter();

  const loadFiles = useCallback(async () => {
    try {
      const querySnapshot = await getDocs(collection(getFirestore(), 'files'));
      if (querySnapshot.metadata.fromCache) {
        throw new Error('No Internet!');
      }

      const loadedFiles: Array<FileObj> = [];
      querySnapshot.forEach((file) => {
        loadedFiles.push({ id: file.id, ...file.data() as Omit<FileObj, 'id'> });
      });

      setFiles(loadedFiles);
      setIsLoading(false);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      message.error('Oops! something went wrong while loading files.');
    }
  }, []);

  const handleSavedFile = useCallback((updatedFile: FileObj) => {
    setFiles((prevFiles) => prevFiles.map(
      (f) => (f.id === updatedFile.id ? updatedFile : f),
    ));
    setEditFile(null);
    setIsSaving(false);
  }, []);

  useEffect(() => {
    loadFiles();
  }, [loadFiles]);

  const reloadFiles = useCallback(() => {
    setIsLoading(true);
    loadFiles();
  }, [loadFiles]);

  const handleEdit = useCallback((file: FileObj) => {
    setEditFile(file);
  }, []);

  const handleDelete = useCallback(async (file: FileObj) => {
    setDeleting((prevDeleting) => [...prevDeleting, file.id]);

    try {
      // do not delete files shared with v1 of the app
      if (file.url.includes('/media-v2%2F')) {
        await deleteObject(ref(getStorage(), file.url));
      }
      await deleteDoc(doc(collection(getFirestore(), 'files'), file.id));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      message.error('Oops! something went wrong while deleting file.');
    }

    setFiles((prevFiles) => prevFiles.filter(({ id }) => id !== file.id));
    setDeleting((prevDeleting) => prevDeleting.filter((id) => id !== file.id));
  }, []);

  const handleEditCancel = useCallback(() => {
    if (isSaving || !editFile) {
      return;
    }

    setEditFile(null);
  }, [isSaving, editFile]);

  const data = files.map((file) => {
    const type = file.name.toLowerCase().endsWith('.mp3') ? 'audio' : 'video';
    return { ...file, type };
  });

  return (
    <>
      <div style={{ marginBottom: 16 }}>
        <Button
          type="primary"
          onClick={reloadFiles}
          loading={isLoading}
          icon={<ReloadOutlined />}
        >
          Reload
        </Button>
      </div>
      <Table
        loading={isLoading}
        dataSource={data}
        rowKey={(file: FileObj) => file.name}
      >
        <Column
          title=""
          dataIndex="type"
          width={50}
          render={(text: string, file: FileObj & { type: string }) => (
            <FontAwesomeIcon icon={file.type === 'audio' ? 'headphones' : 'video'} />
          )}
          filters={[{
            text: 'Audio',
            value: 'audio',
          }, {
            text: 'Video',
            value: 'video',
          }]}
          onFilter={(value, file: FileObj & { type: string }) => file.type === value}
        />
        <Column
          title="Name"
          dataIndex="title"
          filterDropdown={filterDropdown('name', searchInput)}
          filterIcon={searchFilterIcon}
          onFilter={(value, file: FileObj) => file
            .title.toLowerCase().includes((value as string).toLowerCase())}
          onFilterDropdownOpenChange={onFilterDropdownOpenChange(searchInput)}
          render={renderHighlight}
          sorter={(a: FileObj, b: FileObj) => a.title.localeCompare(b.title)}
        />
        <Column
          title="Duration"
          dataIndex="duration"
          render={renderDuration}
          sorter={(a: FileObj, b: FileObj) => a.duration - b.duration}
        />
        <Column
          title="Uploaded"
          dataIndex="uploadedAt"
          render={
            (uploadedAt: Timestamp) => DateTime.fromJSDate(uploadedAt.toDate()).toRelative()
          }
          sorter={(a: FileObj, b: FileObj) => b.uploadedAt.seconds - a.uploadedAt.seconds}
          defaultSortOrder="ascend"
        />
        <Column
          title="Actions"
          key="actions"
          render={(text: string, file: FileObj) => {
            const { id, url, title } = file;
            const isBeingDeleted = deleting.includes(id);
            const confirmTitle = (
              <span>
                {'Are you sure you want to delete '}
                <strong>{title}</strong>
                ?
              </span>
            );

            return (
              <div style={{ whiteSpace: 'nowrap' }}>
                <Button
                  type="primary"
                  size="small"
                  icon={<EditOutlined />}
                  onClick={() => handleEdit(file)}
                  disabled={isBeingDeleted}
                />
                <Divider type="vertical" />
                <Button
                  type="primary"
                  size="small"
                  icon={<CaretRightOutlined />}
                  href={url}
                  target="_blank"
                  className="media-play-icon"
                  disabled={isBeingDeleted}
                />
                <Divider type="vertical" />
                <Popconfirm
                  title={confirmTitle}
                  onConfirm={() => handleDelete(file)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button
                    danger
                    size="small"
                    ghost
                    icon={<DeleteOutlined />}
                    loading={isBeingDeleted}
                  />
                </Popconfirm>
              </div>
            );
          }}
        />
      </Table>

      <FileEditModal
        uneditedFile={editFile}
        handleEditCancel={handleEditCancel}
        setIsSaving={setIsSaving}
        handleSavedFile={handleSavedFile}
        isSaving={isSaving}
      />
    </>
  );
};

export default Files;
