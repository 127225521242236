import React, { useCallback, useEffect, useState } from 'react';
import { DatePicker, Form, Spin, Tabs } from 'antd';
import { Helmet } from 'react-helmet';
import dayjs, { Dayjs } from 'dayjs';

import AuthedPage from '../AuthedPage';
import ErrorBoundary from '../ErrorBoundary';
import Payments from './components/Payments';
import Customers from './components/Customers';
import { PaymentObj } from '../../types';
import { loadPayments } from './paymentHelpers';

const CRM = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [payments, setPayments] = useState<Array<PaymentObj>>([]);
  const [fromDate, setFromDate] = useState<Dayjs | undefined>(() => dayjs().startOf('month'));

  useEffect(() => {
    loadPayments(fromDate?.toDate()).then((loadedPayments) => {
      setPayments(loadedPayments);
      setLoading(false);
    });
  }, [fromDate]);

  const onChangeDate = useCallback((m: Dayjs | null) => {
    setFromDate(m || dayjs().startOf('month'));
    setLoading(true);
  }, []);

  const tabItems = [
    {
      label: 'Donations',
      key: 'payments',
      children: (
        <ErrorBoundary>
          {!loading && <Payments payments={payments}/>}
        </ErrorBoundary>
      ),
    },
    {
      label: 'Donors',
      key: 'customers',
      children: (
        <ErrorBoundary>
          {!loading && <Customers allPayments={payments} />}
        </ErrorBoundary>
      ),
    },
  ];

  return (
    <AuthedPage fullwidth requiredPermission="access_donations">
      <Helmet>
        <title>Donations</title>
      </Helmet>

      <Form initialValues={{ fromDate }}>
        <Form.Item label="Show donations since:" name="fromDate" tooltip="Limit to recent donations for quick loading">
         <DatePicker value={fromDate} onChange={onChangeDate} />
        </Form.Item>
      </Form>
      <Spin spinning={loading} delay={200}>
        <Tabs defaultActiveKey="payments" items={tabItems} />
      </Spin>
    </AuthedPage>
  );
};

export default CRM;
