import React, { useState } from 'react';
import { Tabs } from 'antd';
import { Helmet } from 'react-helmet';

import AuthedPage from '../AuthedPage';
import ErrorBoundary from '../ErrorBoundary';
import Docs from './components/Docs';
import Folders from './components/Folders';
import './DocsAndFolders.scss';
import type { TabbedPageParams } from '../../types';
import { capitalize } from '../../utils';
import { useParams } from 'react-router-dom';

export const DocsFilterContext = React.createContext<{
  filterFolder: string | null,
  setFilterFolder: (folderId: string | null) => void,
  setDocsOrFolders: (docsOrFolders: 'docs' | 'folders') => void,
}>({ filterFolder: null, setFilterFolder: () => {}, setDocsOrFolders: () => {} });

const DocsAndFolders = () => {
  const { tab } = useParams<TabbedPageParams>();
  const [filterFolder, setFilterFolder] = useState<string | null>(null);
  const [docsOrFolders, setDocsOrFolders] = useState<'docs' | 'folders'>('docs');

  if (!tab) {
    return <>Error - no tab is active</>;
  }

  if (!['resources', 'talks', 'meditations'].includes(tab)) {
    return <>Error - invalid tab</>;
  }

  const tabItems = [
    {
      label: 'Docs',
      key: 'docs',
      children: (
        <ErrorBoundary>
          <Docs />
        </ErrorBoundary>
      ),
    },
    {
      label: 'Folders',
      key: 'folders',
      children: (
        <ErrorBoundary>
          <Folders />
        </ErrorBoundary>
      ),
    },
  ];

  return (
    <AuthedPage fullwidth requiredPermission="edit_content">
      <Helmet>
        <title>{capitalize(tab)}</title>
      </Helmet>

      <DocsFilterContext.Provider value={{ filterFolder, setFilterFolder, setDocsOrFolders }}>
        <Tabs
          defaultActiveKey="docs"
          items={tabItems}
          activeKey={docsOrFolders}
          onChange={(tabKey) => setDocsOrFolders(tabKey as 'docs' | 'folders')}
        />
      </DocsFilterContext.Provider>
    </AuthedPage>
  );
};

export default DocsAndFolders;
