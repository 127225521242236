import React, { useMemo } from 'react';
import {
  Alert, Form, Select, Space,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { vimeoGuidelinesUrl } from '../../config';
import { DocObj } from '../../types';
import { FileOption } from './formTypes';
import {
  getVimeoVisibilityDescription,
  getVimeoVisibilityName,
  languageCodeAndName,
  renderDuration,
} from '../../utils';

const { Item: FormItem } = Form;

type Props = {
  doc?: DocObj | null;
  fileOptions: {
    audio: Array<FileOption>;
    video: Array<FileOption>;
  };
  handleMediaURLChange: (option: FileOption, fileType: 'audio' | 'video') => void;
  isDisabled: boolean;
};

const isLegacyMediaUrl = (url: string) => url.includes('firebasestorage.googleapis.com');


const MediaFormItem = ({
  doc, fileOptions, handleMediaURLChange, isDisabled,
}: Props) => {
  const currentVimeo: FileOption | undefined = useMemo(
    () => fileOptions.video.find((v) => v.downloadURL === doc?.mediaURL),
    [fileOptions.video, doc],
  );

  const manageOnVimeo = currentVimeo && (
    <a href={`https://vimeo.com/manage/videos/${currentVimeo.vimeoID}/`} target="_blank" rel="noopener noreferrer">
      Manage video on Vimeo
      {' '}
      <FontAwesomeIcon icon="external-link-alt" />
    </a>
  );
  const managePrivacyOnVimeo = currentVimeo && (
    <a href={`https://vimeo.com/manage/videos/${currentVimeo.vimeoID}/privacy`} target="_blank" rel="noopener noreferrer">
      Manage privacy on Vimeo
      {' '}
      <FontAwesomeIcon icon="external-link-alt" />
    </a>
  );
  const manageSettingsOnVimeo = currentVimeo && (
    <a href={`https://vimeo.com/manage/videos/${currentVimeo.vimeoID}/settings`} target="_blank" rel="noopener noreferrer">
      Manage settings on Vimeo
      {' '}
      <FontAwesomeIcon icon="external-link-alt" />
    </a>
  );

  if (!(doc && doc.type && doc.type !== 'text')) {
    return null;
  }

  return (
    <FormItem
      label={doc.type === 'audio' ? 'Audio file' : 'Video'}
      tooltip={doc.type === 'audio'
        ? 'Please add audio files on Audio page first, then they will appear in this list'
        : 'Please upload videos to Vimeo first, then they will appear in this list'}
    >
      <Select
        value={doc && doc.mediaURL ? doc.mediaURL : ''}
        style={{ width: '100%' }}
        placeholder="Select a file"
        onChange={(value) => {
          if (doc?.type !== 'text') {
            const fileOption = fileOptions[doc.type].find((f) => f.value === value);
            if (fileOption) {
              handleMediaURLChange(fileOption, doc.type);
            }
          }
        }}
        filterOption
        optionFilterProp="children"
        showSearch
        disabled={isDisabled}
      >
        {doc.type === 'video' ? (
          <Select.OptGroup label={`Vimeo (${fileOptions[doc.type].length})`}>
            {fileOptions.video.filter(({ value }) => !isLegacyMediaUrl(value))
              .map(({ value, label }) => (
                <Select.Option key={value} value={value} title={label}>{label}</Select.Option>
              ))}
          </Select.OptGroup>
        ) : (
          <Select.OptGroup label={`Audio (${fileOptions[doc.type].length})`}>
            {fileOptions[doc.type]
              .map(({ value, label }) => (
                <Select.Option key={value} value={value} title={label}>{label}</Select.Option>
              ))}
          </Select.OptGroup>
        )}
      </Select>
      <Space direction="vertical">
        {doc.mediaURL && (
          <>
            <Space style={{ marginTop: 8, marginLeft: 4, fontSize: 'small' }}>
              Play:
              {' '}
              <a href={doc.mediaURL} target="_blank" rel="noopener noreferrer">
                {`${doc.type === 'video' ? 'MP4' : 'MP3'} `}
                <FontAwesomeIcon icon="external-link-alt" />
              </a>
              {' '}
              {currentVimeo?.hlsURL && (
                <a href={currentVimeo.hlsURL} target="_blank" rel="noopener noreferrer">
                  {'HLS '}
                  <FontAwesomeIcon icon="external-link-alt" />
                </a>
              )}
              {renderDuration(doc.duration)}
            </Space>
            {doc.type === 'video' && (
              <Space style={{ marginLeft: 4, fontSize: 'small' }}>
                Video language: {currentVimeo?.language ? languageCodeAndName(currentVimeo?.language) : 'Not set'}
              </Space>
            )}
          </>
        )}
        {doc.type === 'audio' && !doc.mediaURL && (
          <Alert
            type="error"
            style={{ flexDirection: 'column', margin: 6 }}
            message="No audio selected. Please select an audio file from the list."
          />
        )}
        {doc.type === 'video' && (
          <>
            <Alert
              type="info"
              style={{ flexDirection: 'column', margin: 6 }}
              message="Please follow our guidelines when configuring the Vimeo page."
              action={(
                <Space direction="vertical">
                  <a href={vimeoGuidelinesUrl} target="_blank" rel="noopener noreferrer">
                    Read Guidelines
                    {' '}
                    <FontAwesomeIcon icon="external-link-alt" />
                  </a>
                  {manageOnVimeo}
                </Space>
              )}
            />
            {!currentVimeo && (
              <Alert
                type="error"
                style={{ flexDirection: 'column', margin: 6 }}
                message="No video selected. Please select a video from the list."
              />
            )}
            {currentVimeo && !currentVimeo.description && (
              <Alert
                type="warning"
                style={{ flexDirection: 'column', margin: 6 }}
                message="The Vimeo page has no description. The experience of viewers on vimeo.com will be better if you add one."
                action={manageSettingsOnVimeo}
              />
            )}
            {currentVimeo && currentVimeo.rating === 'unrated' && (
              <Alert
                type="error"
                style={{ flexDirection: 'column', margin: 6 }}
                message="The Vimeo page has no content rating. Please set a rating so that people can view it on vimeo.com without age verification."
                action={managePrivacyOnVimeo}
              />
            )}
            {currentVimeo && !currentVimeo.language && (
              <Alert
                type="error"
                style={{ flexDirection: 'column', margin: 6 }}
                message="Please set a language on Vimeo so that we can determine whether we need to show subtitles to the user (depending on their phone settings). The language set should be the language of the audio (not the subtitles or title/description)."
                action={manageSettingsOnVimeo}
              />
            )}
            {currentVimeo?.visibility && (
              <Alert
                type={currentVimeo.visibility === 'anybody' ? 'success' : (currentVimeo.visibility === 'disable' ? 'warning' : 'error')}
                style={{ flexDirection: 'column', margin: 6 }}
                message={`Vimeo visibility: ${getVimeoVisibilityName(currentVimeo.visibility)} ${getVimeoVisibilityDescription(currentVimeo.visibility)}.`}
                action={managePrivacyOnVimeo}
              />
            )}
          </>
        )}
      </Space>
    </FormItem>
  );
};

export default MediaFormItem;
