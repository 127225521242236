import React, {
  useState,
  useCallback, useMemo,
} from 'react';
import { SwapOutlined } from '@ant-design/icons';
import {
  Form, Button, Modal, Spin, message, Select,
} from 'antd';

import type { DocObj, TabValue } from '../../../types';
import { sortCompareForFolder } from '../../../utils';
import SortableList from './SortableList';
import './Reorder.scss';
import { DragEndEvent } from '@dnd-kit/core/dist/types';
import { arrayMove } from '@dnd-kit/sortable';
import deepEqual from 'deep-equal';
import useFolders from '../../../hooks/useFolders';
import {
  collection,
  doc,
  getDocs,
  getFirestore,
  query,
  serverTimestamp,
  Timestamp,
  where,
  writeBatch,
} from 'firebase/firestore';

type FolderOption = {
  title: string;
  value: string;
};

type Props = {
  disabled: boolean;
  tab: TabValue;
  reloadMainTable: () => void;
};

export default function ReorderDocs({ disabled, tab, reloadMainTable }: Props) {
  const [allDocs, setAllDocs] = useState<Array<DocObj>>([]);
  const [activeDocs, setActiveDocs] = useState<Array<DocObj>>([]);
  const [pendingDocs, setPendingDocs] = useState<Array<DocObj>>([]);
  const [activeFolder, setActiveFolder] = useState<string | undefined>(undefined);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const { folders } = useFolders(tab);

  const folderOptions: Array<FolderOption> = useMemo(() => folders.map((folder) => ({
    value: folder.id, title: folder.title,
  })), [folders]);

  const loadItems = useCallback(async () => {
    try {
      const docsSnapshot = await getDocs(query(collection(getFirestore(), 'docs'), where('tab', '==', tab)));

      if (docsSnapshot.metadata.fromCache) {
        throw new Error('No Internet!');
      }

      const docs: Array<DocObj> = [];

      docsSnapshot.forEach((item) => {
        docs.push({ id: item.id, ...item.data() as Omit<DocObj, 'id'> });
      });

      setAllDocs(docs);
      setIsLoading(false);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      message.error('Oops! something went wrong while loading folders.');
    }
  }, [tab]);

  const showModal = useCallback(() => {
    setIsLoading(true);
    setModalVisible(true);
    setActiveFolder(undefined);
    setPendingDocs([]);
    setActiveDocs([]);

    // Reload items every time the modal is opened
    loadItems();
  }, [loadItems]);

  const handleSave = useCallback(async () => {
    setIsSaving(true);

    const batch = writeBatch(getFirestore());

    pendingDocs.forEach(({ id, order, folders: folderOrders }) => {
      const itemRef = doc(collection(getFirestore(), 'docs'), id);

      const oldItem = activeDocs.find(item => item.id === id);

      if (oldItem) {
        const dataToUpdate: {
          order?: number,
          folders?: { [folderId: string]: { order?: number } },
          updatedAt?: Timestamp,
        } = {};

        if (order !== oldItem.order) {
          // Order within primary folder was changed
          dataToUpdate.order = order;
        } else if (activeFolder && folderOrders && folderOrders[activeFolder] !== undefined && !deepEqual(folderOrders, oldItem.folders)) {
          // Order within secondary folder was changed
          dataToUpdate.folders = folderOrders;
        }

        if (Object.keys(dataToUpdate).length > 0) {
          dataToUpdate.updatedAt = serverTimestamp() as Timestamp;
          batch.update(itemRef, dataToUpdate);
        }
      }
    });

    try {
      await batch.commit();
      setModalVisible(false);
      // Reload main table/list
      reloadMainTable();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      message.error('Oops! something went wrong saving new order to database.');
    }

    setIsSaving(false);
  }, [activeFolder, activeDocs, pendingDocs, reloadMainTable]);

  const handleCancel = useCallback(() => {
    setModalVisible(false);
  }, []);

  const handleFolderChange = useCallback((folderId: string) => {
    setActiveFolder(folderId);
    const newActiveDocs = allDocs
      .filter((item) => item.folder === folderId || (item.folders && item.folders[folderId]))
      .sort(sortCompareForFolder(folderId));
    setActiveDocs(newActiveDocs);
    setPendingDocs(newActiveDocs);
  }, [allDocs]);

  const onDragEnd: (event: DragEndEvent) => void = useCallback((event) => {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      setPendingDocs((items) => {
        const oldIndex = items.findIndex((item) => item.id === active.id);
        const newIndex = items.findIndex((item) => item.id === over.id);

        const sortedDocs = arrayMove(items, oldIndex, newIndex);
        return sortedDocs.map((item, index) => {
          const updatedDoc = { ...item };
          if (activeFolder === item.folder) {
            // This is the primary folder for the item
            updatedDoc.order = index;
          } else {
            // This is a secondary folder for the item
            const secondaryFolders: NonNullable<DocObj['folders']> = Object.entries(updatedDoc.folders || {})
              .reduce((dict, [folderId, folderInfo]) => {
                dict[folderId] = {
                  ...folderInfo,
                  order: activeFolder === folderId ? index : folderInfo.order,
                };
                return dict;
              }, {} as { [folderId: string]: { order?: number } });
            updatedDoc.folders = secondaryFolders;
          }
          return updatedDoc;
        });
      });
    }
  }, [activeFolder]);

  return (
    <>
      <Button
        type="primary"
        ghost
        onClick={showModal}
        disabled={disabled}
        icon={<SwapOutlined />}
      >
        Reorder
      </Button>
      <Modal
        title="Reorder docs"
        open={modalVisible}
        okText="Save"
        onOk={handleSave}
        confirmLoading={isSaving}
        onCancel={handleCancel}
      >
        <Spin spinning={isLoading}>
          <Form.Item label="Folder">
            <Select
              value={activeFolder}
              style={{ width: '100%' }}
              placeholder="Select a folder"
              onChange={handleFolderChange}
              disabled={isLoading || isSaving}
              showSearch
              filterOption={(input, option) =>
                (option?.label as string || '').toLowerCase().includes(input.toLowerCase())
              }
              options={folderOptions.map(({ value, title }) => ({ value, label: title }))}
            />
          </Form.Item>
          <SortableList
            items={pendingDocs}
            onDragEnd={onDragEnd}
          />
        </Spin>
      </Modal>
    </>
  );
}
