import {
  Checkbox, Form, Input, message, Modal, Table,
} from 'antd';
import React, {
  ChangeEvent, useCallback, useMemo, useState,
} from 'react';

import type { Permission, UserObj } from '../../types';
import { getFunctions, httpsCallable } from 'firebase/functions';

const defaultPermissions = {
  edit_content: false,
  access_donations: false,
  manage_users: false,
};

type Props = {
  users: UserObj[],
  onSuccess: () => void | Promise<void>,
  onClose: () => void,
};

function ApproveUserModal({ users, onSuccess, onClose }: Props) {
  const [email, setEmail] = useState('');
  const [showInvalidEmailError, setShowInvalidEmailError] = useState<boolean>(false);
  const [permissions, setPermissions] = useState<typeof defaultPermissions>(defaultPermissions);
  const [userNotFound, setUserNotFound] = useState<boolean>(false);
  const [fetchingUser, setFetchingUser] = useState<boolean>(false);
  const [approvingUser, setApprovingUser] = useState<boolean>(false);
  const [user, setUser] = useState<UserObj | null>(null);

  const existingUserEmails = useMemo(() => users.map((usr) => usr.email), [users]);

  const handleEmailChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.currentTarget.value);
    setShowInvalidEmailError(false);
    setUser(null);
    setUserNotFound(false);
  }, []);

  const fetchUserDetails = useCallback(async () => {
    if (!/.+@.+\..+/.test(email)) {
      setShowInvalidEmailError(true);
      return;
    }

    if (existingUserEmails.includes(email)) {
      return;
    }

    setFetchingUser(true);
    setUserNotFound(false);
    setPermissions(defaultPermissions);
    try {
      const getAdminUserByEmail = httpsCallable<{ email: string }, UserObj>(getFunctions(), 'getAdminUserByEmail');
      const response = await getAdminUserByEmail({ email });
      if (response.data) {
        setUser(response.data);
      } else {
        setUserNotFound(true);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      message.error('Oops! something went wrong while fetching user details.');
    }
    setFetchingUser(false);
  }, [email, existingUserEmails]);

  const togglePermission = useCallback((name: Permission) => {
    setPermissions((currentPermissions) => {
      const newPermissions = { ...currentPermissions };
      newPermissions[name] = !currentPermissions[name];
      return newPermissions;
    });
  }, []);

  const handleApprove = useCallback(async () => {
    if (!user) {
      return;
    }
    setApprovingUser(true);
    try {
      const addAdminUser = httpsCallable<{ uid: string, permissions: typeof defaultPermissions }>(getFunctions(), 'addAdminUser');
      await addAdminUser({ uid: user.uid, permissions });
      message.success('Successfully approved new admin user');
      onSuccess();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      message.error('Oops! something went wrong while approving new admin user.');
    }
    setApprovingUser(false);
    onClose();
  }, [user, onClose, permissions, onSuccess]);

  const columns = useMemo(() => [
    {
      title: 'Photo',
      dataIndex: 'photoURL',
      key: 'photo',
      render: (photoURL: string) => <img src={photoURL} alt="" height="80" width="80" />,
    },
    {
      title: 'Name',
      dataIndex: 'displayName',
      key: 'name',
    },
    {
      title: 'Permissions',
      children: [{
        title: 'Content',
        key: 'content',
        render: () => (
          <Checkbox
            checked={permissions.edit_content}
            onChange={() => togglePermission('edit_content')}
          />
        ),
      }, {
        title: 'Donations',
        key: 'donations',
        render: () => (
          <Checkbox
            checked={permissions.access_donations}
            onChange={() => togglePermission('access_donations')}
          />
        ),
      }, {
        title: 'Users',
        key: 'users',
        render: () => (
          <Checkbox
            checked={permissions.manage_users}
            onChange={() => togglePermission('manage_users')}
          />
        ),
      }],
    },
  // eslint-disable-next-line max-len
  ], [permissions.access_donations, permissions.edit_content, permissions.manage_users, togglePermission]);

  return (
    <Modal
      open
      okText="Approve"
      okButtonProps={{ disabled: !user || existingUserEmails.includes(email) }}
      confirmLoading={approvingUser}
      cancelButtonProps={{ disabled: approvingUser }}
      onOk={handleApprove}
      onCancel={onClose}
      closable={!approvingUser}
      width="80%"
      style={{ maxWidth: 720 }}
    >
      <Form layout="vertical">
        <Form.Item
          label="Find user by email"
          style={{ maxWidth: 400 }}
          validateStatus={showInvalidEmailError ? 'error' : undefined}
          hasFeedback={showInvalidEmailError}
          extra={showInvalidEmailError ? 'Please enter a valid email address.' : undefined}
        >
          <Input.Search
            value={email}
            onChange={handleEmailChange}
            onSearch={fetchUserDetails}
            loading={fetchingUser}
          />
        </Form.Item>
      </Form>
      {existingUserEmails.includes(email) && (
        <p>A user with this email id already exists.</p>
      )}
      {userNotFound && (
        <p>
          We could not find a user with this email.
          Please make sure this email belongs to a Google account
          and that the user has signed into the admin.
        </p>
      )}
      {(user || fetchingUser) && (
        <Table
          loading={fetchingUser}
          dataSource={user ? [user] : []}
          columns={columns}
          rowKey={(usr: UserObj) => usr.email}
          pagination={false}
        />
      )}
    </Modal>
  );
}

export default ApproveUserModal;
