import React, { useCallback, useState } from 'react';
import { getAuth, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { GoogleOutlined } from '@ant-design/icons';
import { message, Button } from 'antd';

import logo from '../../images/logo-light.png';
import './Login.scss';

const provider = new GoogleAuthProvider();

const Login = () => {
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = useCallback(() => {
    setIsLoading(true);
    signInWithPopup(getAuth(), provider)
      .catch((error) => {
        setIsLoading(false);
        // eslint-disable-next-line no-console
        console.error(`${error.code}\n${error.message}`);
        message.error('Oops! something went wrong. Please try again.');
      });
  }, []);

  return (
    <div className="login">
      <img className="login__logo" src={logo} alt="Plum Village logo" />
      <h1>Log in</h1>
      <Button
        type="primary"
        size="large"
        block
        icon={<GoogleOutlined />}
        loading={isLoading}
        onClick={handleClick}
      >
        Log in with Google
      </Button>
    </div>
  );
};

export default Login;
