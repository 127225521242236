import React from 'react';
import { Tabs } from 'antd';
import { Helmet } from 'react-helmet';

import AuthedPage from '../AuthedPage';
import PodcastSyncer from './PodcastSyncer';

export default function SyncPodcasts() {
  const tabItems = [
    {
      label: 'From tnhaudio.org',
      key: 'tnhaudio.org',
      children: (
        <PodcastSyncer
          sourceURL="https://tnhaudio.org/feed"
          sourceName="tnhaudio.org"
          tab="talks"
          folder="94og6J1Tf58AS6syuwFA" // Dharma Talks
          overrideAuthor="Thich Nhat Hanh"
        />
      ),
    },
    {
      label: 'From plumvillage.org/audio-podcasts',
      key: 'pvorg',
      children: (
        <PodcastSyncer
          sourceURL="https://plumvillage.org/feed/audio/dharma-talks"
          sourceName="plumvillage.org/audio-podcasts"
          tab="talks"
          folder="rNGFXADiWFUddriKgJ1f" // Recent Dharma Talks
        />
      ),
    },
  ];

  return (
    <AuthedPage requiredPermission="edit_content">
      <Helmet>
        <title>Sync Podcasts</title>
      </Helmet>

      <Tabs items={tabItems}>
      </Tabs>
    </AuthedPage>
  );
}
