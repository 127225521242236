import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';

import type { PaymentObj, CustomerObj, PaypalTransaction, StripeChargeData } from '../../types';
import dayjs from 'dayjs';

export const isPayPalAppDonation = (transactionData: PaypalTransaction) => {
  // PayPal may be used for purposes other than donations.
  // Exclude items which are not PV App donations
  return parseFloat(transactionData.transaction_info.transaction_amount.value) > 0
    && transactionData.cart_info.item_details && transactionData.cart_info.item_details.length
    && transactionData.payer_info.email_address;
};

export const loadPayPalPaymentRecord = (id: string, transactionData: PaypalTransaction): PaymentObj => ({
  id,
  paymentDate: transactionData.transaction_info.transaction_initiation_date,
  email: transactionData.payer_info.email_address,
  customerName: transactionData.payer_info.payer_name.alternate_full_name
    || transactionData.shipping_info.name,
  countryCode: transactionData.payer_info.country_code,
  amount: parseFloat(transactionData.transaction_info.transaction_amount.value),
  currency: transactionData.transaction_info.transaction_amount.currency_code,
  description: transactionData.transaction_info.transaction_subject,
  status: transactionData.transaction_info.transaction_status,
  isSubscription: transactionData.transaction_info.paypal_reference_id_type === 'SUB',
  platform: 'paypal',
});

export const loadStripePaymentRecord = (id: string, chargeData: StripeChargeData): PaymentObj => ({
  id,
  paymentDate: dayjs.unix(chargeData.created).toISOString(),
  email: chargeData.billing_details.email || chargeData.invoice.customer_email,
  customerName: chargeData.billing_details.name || chargeData.invoice.customer_name || '',
  countryCode: chargeData.billing_details.address.country,
  amount: chargeData.amount / 100,
  currency: chargeData.currency.toUpperCase() as 'USD' | 'GBP' | 'EUR',
  description: (chargeData.invoice && chargeData.invoice.lines.data[0].description)
    || chargeData.statement_descriptor,
  status: chargeData.status,
  isSubscription: Boolean(chargeData.invoice
    && ['subscription', 'recurring'].includes(chargeData.invoice.lines.data[0].type)),
  platform: 'stripe',
});

export const getPaymentStatusColour = (paymentStatus: string) => {
  switch (paymentStatus) {
    case 'S':
    case 'succeeded':
      return 'green';
    case 'D':
    case 'failed':
      return 'red';
    default:
      return 'grey';
  }
};

export const getPaymentStatusDescription = (paymentStatus: string) => {
  switch (paymentStatus) {
    case 'S':
      return ' - The transaction successfully completed without a denial and after any pending statuses.';
    case 'D':
      return ' - PayPal or merchant rules denied the transaction.';
    case 'F':
      return ' - The original recipient partially refunded the transaction.';
    case 'P':
      return ' - The transaction is pending.';
    case 'V':
      return ' - A successful transaction was reversed and funds were refunded to the original sender.';
    default:
      return '';
  }
};

export const loadPayments = async (fromDate?: Date | null, emailAddress?: string): Promise<PaymentObj[]> => {
  const db = getFirestore();
  const paypalCol = collection(db, 'paypal-transactions');
  const stripeCol = collection(db, 'stripe-charges');

  // Run queries for paypal + stripe in parallel to speed up loading

  let paypalQuery = query(paypalCol);
  if (fromDate) {
    paypalQuery = query(
      paypalQuery,
      where('transaction_info.transaction_initiation_date', '>=', fromDate.toISOString().substring(0, 10)),
    );
  }
  if (emailAddress) {
    const lowerCaseEmail = emailAddress.toLowerCase();
    paypalQuery = query(
      paypalQuery,
      lowerCaseEmail === emailAddress
        ? where('payer_info.email_address', '==', emailAddress)
        // If email was not lowercase, search for both exact case and lowercase
        : where('payer_info.email_address', 'in', [lowerCaseEmail, emailAddress]),
    );
  }

  const paypalPaymentsPromise: Promise<Array<PaymentObj>> = getDocs(paypalQuery).then((paypalQuerySnapshot) => {
    if (paypalQuerySnapshot.metadata.fromCache) {
      throw new Error('No Internet!');
    }

    const payments: Array<PaymentObj> = [];
    paypalQuerySnapshot.forEach((transaction) => {
      const transactionData = transaction.data() as PaypalTransaction;
      if (!isPayPalAppDonation(transactionData)) {
        return;
      }
      payments.push(loadPayPalPaymentRecord(transaction.id, transactionData));
    });
    return payments;
  });

  let stripeQuery = query(stripeCol);
  if (fromDate) {
    stripeQuery = query(
      stripeQuery,
      where('created', '>=', fromDate.valueOf() / 1000),
    );
  }
  if (emailAddress) {
    const lowerCaseEmail = emailAddress.toLowerCase();
    stripeQuery = query(
      stripeQuery,
      lowerCaseEmail === emailAddress
        ? where('billing_details.email', '==', emailAddress)
        // If email was not lowercase, search for both exact case and lowercase
        : where('billing_details.email', 'in', [lowerCaseEmail, emailAddress]),
    );
  }
  const stripePaymentsPromise: Promise<Array<PaymentObj>> = getDocs(stripeQuery).then((stripeQuerySnapshot) => {
    if (stripeQuerySnapshot.metadata.fromCache) {
      throw new Error('No Internet!');
    }
    const payments: Array<PaymentObj> = [];
    stripeQuerySnapshot.forEach((charge) => {
      const chargeData = charge.data() as StripeChargeData;
      payments.push(loadStripePaymentRecord(charge.id, chargeData));
    });
    return payments;
  });

  return [...await paypalPaymentsPromise, ...await stripePaymentsPromise];
};

export const getCustomersMap = (payments: Array<PaymentObj>): { [key: string]: CustomerObj } => {
  const customersMap: { [key: string]: CustomerObj } = {};
  const aMonthAgo = dayjs().subtract(1, 'month');
  payments.forEach((payment) => {
    const customerKey = payment.email.toLowerCase();
    let customer = customersMap[customerKey];
    if (!customer) {
      customer = {
        email: payment.email,
        name: payment.customerName,
        countryCode: payment.countryCode,
        firstPaymentDate: payment.paymentDate,
        lastPaymentDate: payment.paymentDate,
        isSubscriber: false,
        isActiveSubscriber: false,
        payments: [],
        total: 0,
        currencies: new Set(),
      };
      customersMap[customerKey] = customer;
    }
    customer.payments.push(payment);
    customer.lastPaymentDate = payment.paymentDate;
    customer.total += payment.amount;
    customer.currencies.add(payment.currency);
    if (payment.isSubscription) {
      customer.isSubscriber = true;
      if (dayjs(payment.paymentDate).isAfter(aMonthAgo)) {
        customer.isActiveSubscriber = true;
      }
    }
  });
  return customersMap;
};

export const dateFilterOptions: Array<{ text: string, value: dayjs.ManipulateType }> = [{
  text: 'Past day',
  value: 'day',
}, {
  text: 'Past Week',
  value: 'week',
}, {
  text: 'Past Month',
  value: 'month',
}];
