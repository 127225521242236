import React, { useState, useEffect } from 'react';
import { Form } from 'antd';

import { DocMetadataObj, DocObj, DocSubtitleObj } from '../../types';
import SubtitleLangTags from '../SubtitleLangTags';
import { collection, doc, getDocs, getFirestore } from 'firebase/firestore';

const { Item: FormItem } = Form;

type Props = {
  item?: DocObj | null;
  docMetadata?: DocMetadataObj | null,
};

const SubtitleFormItem = ({ item, docMetadata }: Props) => {
  const [docSubtitles, setDocSubtitles] = useState<Array<DocSubtitleObj> | null>(null);

  useEffect(() => {
    if (item && docMetadata) {
      setTimeout(() => {
        getDocs(collection(doc(collection(getFirestore(), 'docs'), item.id), 'subtitles'))
          .then((querySnapshot) => {
            const subs: Array<DocSubtitleObj> = [];
            querySnapshot.forEach((result) => {
              subs.push({ ...result.data() as DocSubtitleObj });
            });
            setDocSubtitles(subs);
          });
      }, 500); // Delay to allow for importing of subtitles
    }
  }, [item, docMetadata]);

  if (!(item && item.type === 'video')) {
    return null;
  }

  return (
    <FormItem label="Subtitles" tooltip="These will be automatically imported, where possible, from YouTube.">
      {docSubtitles && docSubtitles.length ? (
        <SubtitleLangTags subtitles={docSubtitles} />
      ) : (
        <span>{docSubtitles ? 'None' : 'Loading...'}</span>
      )}
    </FormItem>
  );
};

export default SubtitleFormItem;
